import React, { forwardRef, useEffect } from 'react';
import { Typography, Button, Dialog, DialogContent, Divider, Box, Grow } from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import FileUpload from 'components/ImageUpload';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const ProtectionPopup = ({
  handleClose,
  protectionState,
  onConfirm,
  mode,
  addProtection,
  updateProtection,
  uploadedFile,
  setUploadedFile,
}) => {
  const classes = useStyles();

  const sendForm = () => {
    if (mode == 'add') {
      addProtection();
    } else {
      updateProtection(protectionState);
    }
    if (onConfirm) {
      onConfirm(protectionState);
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode == 'add' ? 'Add Partners to PPF Protection' : 'Edit Partners to PPF Protection'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box>
            <Grid container style={{ justifyContent: 'center' }}>
              <FileUpload
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                partnerId={protectionState}
              />
            </Grid>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button
              // disabled={isSaveDisabled}
              size="small"
              variant="contained"
              color="primary"
              onClick={sendForm}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Edit'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProtectionPopup;
