import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import { CREATE_STAFF, DELETE_STAFF, UPDATE_STAFF, GET_STAFF, LOAD_STAFF } from './actionType';

const initial = initialState.staff;

const reducer = handleActions(
  {
    [LOAD_STAFF]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [CREATE_STAFF]: (state, { payload }) => {
      return {
        ...state,
        users: {
          ...state.users,
          data: [...[payload], ...state.users.data],
        },
        isLoad: false,
      };
    },
    [GET_STAFF]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        users: payload.users,
        isLoad: false,
      };
    },
    [DELETE_STAFF]: (state, { payload }) => {
      console.log(state, 'state123123123');
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter((user) => user != payload),
        },
      };
    },
    [UPDATE_STAFF]: (state, { payload }) => {
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users?.data.map((user) => (user == payload.id ? payload : user)) || [],
        },
      };
    },
  },

  initial
);

export default reducer;
