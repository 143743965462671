import React, { forwardRef, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Grow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import FileUpload from 'components/ImageUpload';
import axios from 'axios';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const CustomersPopup = ({
  handleClose,
  customers,
  onConfirm,
  mode,
  addReview,
  updateReview,
  uploadedFile,
  setUploadedFile,
  setValue,
  reset,
  errors,
  control,
  serverError,
  register,
  handleSubmit,
}) => {
  const classes = useStyles();

  const sendForm = (data) => {
    if (mode === 'add') {
      addReview(data);
    } else {
      updateReview(customers?.id, data);
    }

    if (onConfirm) {
      onConfirm(customers?.id);
      reset();
    }
  };
  useEffect(() => {
    if (customers) {
      setValue('name', customers?.name);
      setValue('comment', customers?.comment);
    }
  }, [customers, setValue]);

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode === 'add' ? 'Add image to Customer Say' : 'Edit image to Customer Say'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} autoComplete="off">
          <form onSubmit={handleSubmit(sendForm)} noValidate>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    fullWidth
                    type="text"
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Comment"
                    fullWidth
                    type="text"
                    {...register('comment')}
                    error={!!errors.comment}
                    helperText={errors.comment?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileUpload
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    customerSayId={customers}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.buttonWrapper}>
              <Button
                size="small"
                variant="contained"
                className={classes.cancel}
                onClick={handleClose}>
                <Typography variant="subtitle3">Cancel</Typography>
              </Button>
              <Button size="small" variant="contained" color="primary" type="submit">
                <Typography variant="subtitle4">{mode === 'add' ? 'Create' : 'Edit'}</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomersPopup;
