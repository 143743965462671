// AdditionalService Component
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import CarWindowPopup from './Dialog/CarWindowPopup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { Empty } from 'antd';
import { detailsSchema } from 'utils/formValidation';
import ImageUpload from 'components/ImageUpload';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'universal-cookie';

const CarWindowTint = () => {
  const cookies = new Cookies();

  const classes = useStyles();
  const [additionalService, setAdditionalService] = useState([]);
  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [passengersCount, setPassengersCount] = useState(0);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    field,
  } = useForm({
    resolver: yupResolver(detailsSchema),
    defaultValues: {
      description_en: '',
      description_fr: '',
      durationInMinutes: 0,
      price: 0,
      discountPrice: 0,
    },
  });

  const getAdditionalService = () => {
    return axios.get(`${process.env.REACT_APP_API_KEY}coverbox/public/getAdditionalServiceDTO`, {
      params: {
        page: controller.page,
        size: controller.rowsPerPage,
      },
    });
  };

  const getAdditionalServiceById = (additionalServiceId) => {
    return axios.get(
      `${process.env.REACT_APP_API_KEY}coverbox/public/getAdditionalServiceDTOById`,
      {
        params: {
          additionalServiceId,
        },
      }
    );
  };
  const getAdditionalServices = () => {
    getAdditionalService()
      .then((response) => {
        const imageData = response.data;
        localStorage.setItem('additionalService', JSON.stringify(imageData));
        const promises = imageData.map((item) => getAdditionalServiceById(item.id));
        return Promise.all(promises);
      })
      .then((responses) => {
        const imagesData = responses.map((response) => response.data);
        setAdditionalService(imagesData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAdditionalServices();
  }, []);

  const deleteCarWindow = async (additionalServiceDTO) => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      await axios.delete(
        `${process.env.REACT_APP_API_KEY}coverbox/management/service/delete?additionalServiceDTO=${additionalServiceDTO}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      localStorage.removeItem('additionalService');
      const services = additionalService.filter((id) => id !== additionalServiceDTO);
      setAdditionalService(services);
      handleDialogState({ delete: false });
    } catch (error) {
      console.log(error);
    }
    getAdditionalServices();
    // handleDialogState({ delete: false });
  };

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };

  const addDetail = async (data) => {
    const requestBody = {
      description_en: data.description_en,
      description_fr: data.description_fr,
      durationInMinutes: data.durationInMinutes,
      price: data.price,
      discountPrice: data.discountPrice,
    };

    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}coverbox/management/service/create`,
        requestBody,
        {
          headers: {
            'X-XSRF-TOKEN': xsrfToken,
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        setAdditionalService((prevState) => {
          const updatedAdditionalService = [...prevState]; // Create a copy of the array
          updatedAdditionalService.push(data);
          return updatedAdditionalService;
        });
        localStorage.setItem('additionalService', JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateDetail = async (id, data) => {
    const requestBody = {
      id: id,
      description_en: data.description_en,
      description_fr: data.description_fr,
      durationInMinutes: data.durationInMinutes,
      price: data.price,
      discountPrice: data.discountPrice,
    };
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const response = await axios.put(
      `${process.env.REACT_APP_API_KEY}coverbox/management/service/update`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-XSRF-TOKEN': xsrfToken,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response.status === 200) {
      localStorage.setItem('additionalService', JSON.stringify(response.data));
      const data = response.data;
      setAdditionalService((prevState) => {
        const updatedAdditionalService = prevState.map((item) => {
          if (item.id === data.id) {
            return { ...item, ...data };
          }
          return item;
        });
        return updatedAdditionalService;
      });
      setValue('description_en', data.description_en);
      setValue('description_fr', data.description_fr);
      setValue('durationInMinutes', data.durationInMinutes);
      setValue('price', data.price);
      setValue('discountPrice', data.discountPrice);
    }
  };

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  function calculateDiscountPrice(originalPrice, discountPercentage) {
    const discountAmount = (originalPrice * discountPercentage) / 100;
    const finalPrice = originalPrice - discountAmount;
    return finalPrice % 1 === 0 ? finalPrice.toFixed(0) : finalPrice.toFixed(2);
  }

  return (
    <Card data-tid="staff-page">
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.header}>
        <Grid item xs={5} sm={7} md={4} lg={3}>
          {/* <BasicSearch value={searchQuery} onChange={setSearchQuery} /> */}
        </Grid>
        <Grid
          item
          xs={7}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button variant="contained" onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Upload image</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <CarWindowPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onConfirm={() => handleDialogState({ add: false })}
          additionalService={dialogs.add}
          addDetail={addDetail}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          setValue={setValue}
        />
      )}

      {dialogs.edit !== false && (
        <CarWindowPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          additionalService={dialogs.edit}
          updateDetail={updateDetail}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          setValue={setValue}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this data?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteCarWindow}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          {additionalService.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="tableTitle"> NAME</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> PRICE</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> DURATION </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> ACTIONS</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {additionalService.length ? (
              additionalService.map((value) => {
                return (
                  <TableRow hover key={value?.id} className={classes.cursor}>
                    <TableCell sx={{ maxWidth: '100px', overflow: 'hidden' }}>
                      <Typography variant="text">{value?.description_en}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">
                        {value.discountPrice
                          ? calculateDiscountPrice(value?.price, value?.discountPrice)
                          : value.price}{' '}
                        CHF
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{value?.durationInMinutes}</Typography>
                    </TableCell>

                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleDialogState({ edit: value })}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => handleDialogState({ delete: value.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Empty />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount.length}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default CarWindowTint;
