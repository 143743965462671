import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStyles } from '../styles';
import { authPaths } from 'constants/auth';
import api from 'api/axios';
import { loginSchema } from 'utils/formValidation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'universal-cookie';

const LoginForm = () => {
  const cookies = new Cookies();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [serverError, setServerError] = useState('');

  const navigate = useNavigate();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const onSubmit = async () => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const response = await api.post(
        'auth/authenticate',
        {
          username: username,
          password: password,
        },
        {
          headers: {
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      const token = response.data.accessToken;
      const refreshToken = response.data.refreshToken;
      const user = response.data.userId;
      localStorage.setItem('accessToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userId', user);

      if (user) {
        navigate('/calendar');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setServerError('Invalid username or password.');
      } else {
        console.log(error);
      }
    }
  };

  return (
    <Box data-tid="login-form" className={classes.wrapper}>
      <Card className={classes.form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p" className={classes.textAttribute}>
                Sign In to ARS CUSTOM
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {serverError && (
                <Typography variant="inherit" className={classes.serverError}>
                  {serverError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="username"
                name="username"
                label="Username"
                fullWidth
                margin="dense"
                {...register('username')}
                error={errors.username ? true : false}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
              <Typography variant="inherit" className={classes.red}>
                {errors.username?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                margin="dense"
                {...register('password')}
                error={errors.password ? true : false}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
              <Typography variant="inherit" className={classes.red}>
                {errors.password?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.textAttribute} size="small">
                <Link className={classes.linkedText} to={authPaths.resetPassword}>
                  Forgot Password?
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit" className={classes.submitBtn}>
                <Typography variant="h5">Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Box>
  );
};

export default LoginForm;
