import React, { useEffect, useState } from 'react';
import { Gallery, NotFound } from '../pages';
import { useRoutes } from 'react-router-dom';
import { AuthRouter } from '../router/routes';
import { GetAppGlobalRoutes } from '../config/routerConfig';
import { useStyles } from './styles';

const App = () => {
  const role = 'admin';
  const [status, setStatus] = useState(false);

  useEffect(() => {
    initUsers();
  }, []);

  const initUsers = async () => {
    setStatus(!status);
  };

  const routes = useRoutes([
    ...AuthRouter.routes,
    ...GetAppGlobalRoutes(role),
    { path: '*', element: <NotFound /> },
  ]);

  const classes = useStyles();

  return <div className={classes.container}> {status && routes}</div>;
};

export default App;
