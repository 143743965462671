import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import profile from '../../assets/images/profile_blanl.png';
import Cookies from 'universal-cookie';

const Image = ({ id, mode }) => {
  const cookies = new Cookies();

  const [imageUrl, setImageUrl] = useState('');
  const uploadfile = 'Uploaded File';

  const fetchImage = async () => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      let url = '';
      let params = {};

      let headers = {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': xsrfToken,
      };

      switch (mode) {
        case 'partners':
          url = `${process.env.REACT_APP_API_KEY}coverbox/public/files/partner/image`;
          params = { partnerId: id };
          break;
        case 'images':
          url = `${process.env.REACT_APP_API_KEY}coverbox/public/files/other/image`;
          params = { imageId: id };
          break;
        case 'tintGlassServiceId':
          url = `${process.env.REACT_APP_API_KEY}coverbox/public/files/tintglass/image`;
          params = { tintGlassServiceId: id };
          break;
        case 'customerSayId':
          url = `${process.env.REACT_APP_API_KEY}coverbox/public/files/customersay/image`;
          params = { customerSayId: id };
          break;
        default:
          break;
      }

      const response = await axios.get(url, {
        params: params,
        responseType: 'blob',
        headers: headers,
      });

      if (response && response.status === 200) {
        const image = URL.createObjectURL(response.data);
        setImageUrl(image);
      } else {
        console.log('Invalid response:', response);
      }
    } catch (error) {
      console.log('Error fetching image:', error);
    }
  };

  const memoizedId = useMemo(() => id, [id]);

  useEffect(() => {
    fetchImage();
  }, [memoizedId]);

  return (
    <Box>
      {imageUrl ? (
        <img src={imageUrl} alt={uploadfile} style={{ width: '150px', height: '150px' }} />
      ) : (
        <img src={profile} alt="profile" style={{ width: '100px', height: '100px' }} />
      )}
    </Box>
  );
};

export default Image;
