import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import AppointmentPopup from './Dialog/AppointmentPopup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import axios from 'axios';
import { appointmentSchema } from 'utils/formValidation';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Empty } from 'antd';
import Cookies from 'universal-cookie';

const Appointments = () => {
  const classes = useStyles();
  const [passengersCount, setPassengersCount] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [serverError, setServerError] = useState('');
  const [tintGlass, setTintGlass] = useState([]);
  const [additionalService, setAdditionalService] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [validate, setValidate] = useState('');
  const cookies = new Cookies();

  // const [registeredAppointService, setRegisteredAppointService] = useState([]);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    control,
    setValue,
    formState: { errors, isDirty },
    reset,
    field,
  } = useForm({
    resolver: yupResolver(appointmentSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      additionalServiceIds: [],
      durationInMinutes: 0,
      date: new Date(),
      time: '',
      tintGlassId: '',
      // frontPositionPercentage: 100,
      // backPositionPercentage: 100,
      enabled: false,
    },
  });

  const dateWatcher = watch('date');
  const timeWatcher = watch('time');
  const durationInMinutes = watch('durationInMinutes');
  const tintGlassId = watch('tintGlassId');

  const selectedDate = moment(dateWatcher, 'YYYY-MM-DD');
  const selectedTime = moment(timeWatcher, 'HH:mm');
  const combinedDateTime = selectedDate.set({
    hour: selectedTime.hour(),
    minute: selectedTime.minute(),
    second: 0,
  });

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });
    if (event) event.stopPropagation();
  };

  const deleteAppointment = async (appointmentId) => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      await axios.delete(
        `${process.env.REACT_APP_API_KEY}coverbox/management/appointment/delete/${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      localStorage.removeItem('appointments');
      setAppointments((prevState) => prevState.filter((id) => id !== appointmentId));
      handleDialogState({ delete: false });
    } catch (error) {
      console.log(error);
    }
    getAllAppointments();

    // handleDialogState({ delete: false });
  };

  const getAllAppointments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/management/appointment/getAll`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      localStorage.setItem('appointments', JSON.stringify(appointments));
      setAppointments(response.data);
      setPassengersCount(response.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAppointments();
  }, [controller]);

  const areAllSelectedServicesIncluded = selectedServices.every((id) =>
    additionalService.some((service) => service.id === id)
  );

  let totalAdditionalPrice = 0;

  if (areAllSelectedServicesIncluded) {
    totalAdditionalPrice = selectedServices.reduce((total, serviceId) => {
      const selectedService = additionalService.find((service) => service.id === serviceId);
      if (selectedService) {
        return total + Number(selectedService.price);
      }
      return total;
    }, 0);
  }

  const selectedTintGlass = tintGlass?.find((item) => item.id == tintGlassId);

  function calculateDiscountPrice(originalPrice, discountPercentage) {
    const discountAmount = (originalPrice * discountPercentage) / 100;
    const finalPrice = originalPrice - discountAmount;
    if (finalPrice % 1 === 0) {
      return finalPrice.toFixed(0) + totalAdditionalPrice;
    } else {
      return finalPrice.toFixed(2) + totalAdditionalPrice;
    }
  }

  const finalPrice = selectedTintGlass?.discountPrice
    ? calculateDiscountPrice(selectedTintGlass?.price, selectedTintGlass?.discountPrice)
    : selectedTintGlass?.price;

  const addAppointment = async (data) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const requestBody = {
      customer: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data?.phoneNumber?.replaceAll(/[+() -]/g, ''),
      },
      appointmentTime: combinedDateTime.format('YYYY-MM-DD HH:mm:ss'),
      durationInMinutes: totalDuration !== 0 ? totalDuration : durationInMinutes,
      tintGlassId: data.tintGlassId,
      additionalServiceIds: selectedServices,
      // frontPositionPercentage: data.frontPositionPercentage,
      // backPositionPercentage: data.backPositionPercentage,
    };
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}coverbox/management/admin/appoint`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      if (response.status === 200) {
        getAllAppointments();
        localStorage.setItem('appointments', JSON.stringify(response.data));
        // handleClose();
        setValidate('success');
      }
    } catch (error) {
      if (error.response) {
        setServerError(error.response.data.message);
        setValidate('Select another date or time');
      } else {
        console.log(error);
      }
      if (error.response) {
        setError('appointmentTime', { type: 'custom', message: 'something' });
        setServerError(error.response.data.message);
      }
    }
  };

  const updateAppointment = async (id, data) => {
    const requestBodyEdit = {
      id: id,
      enabled: data?.enabled,
      customer: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: data?.phoneNumber?.replaceAll(/[+() -]/g, ''),
      },
      appointmentTime: combinedDateTime.format('YYYY-MM-DD HH:mm:ss'),
      durationInMinutes: Number(data?.durationInMinutes),
      tintGlassId: data?.tintGlassId,
      additionalServiceIds: data?.additionalServiceIds,
      // frontPositionPercentage: data?.frontPositionPercentage,
      // backPositionPercentage: data?.backPositionPercentage,
      totalNormalPrice: 0,
      totalDiscountPrice: selectedTintGlass?.discountPrice,
      totalPrice: finalPrice,
    };
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const response = await axios.put(
        `${process.env.REACT_APP_API_KEY}coverbox/management/appointment/update`,
        requestBodyEdit,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      if (response.status === 200) {
        // handleClose();
        const appointmentData = response.data;

        setAppointments((prevState) => {
          const updatedAppoint = prevState.map((item) => {
            if (item.id === appointmentData.id) {
              return { ...item, ...appointmentData };
            }
            return item;
          });
          return updatedAppoint;
        });

        localStorage.setItem('appointments', JSON.stringify(response.data));

        setValue('firstName', appointmentData.customer.firstName);
        setValue('lastName', appointmentData.customer.lastName);
        setValue('email', appointmentData.customer.email);
        setValue('phoneNumber', appointmentData.customer.phoneNumber);
        setValue('tintGlassId', appointmentData.tintGlassId);
        setValue('additionalServiceIds', appointmentData.additionalServiceIds);
        // setValue('frontPositionPercentage', appointmentData.frontPositionPercentage);
        // setValue('backPositionPercentage', appointmentData.backPositionPercentage);
        setValue('date', new Date(appointmentData.date));
        setValue('time', new Date(appointmentData.time));
        setValue('enabled', appointmentData.enabled);
        setValue('durationInMinutes', appointmentData.durationInMinutes);
      }
    } catch (error) {
      if (error.response) {
        setServerError(error.response.data.message);
      } else {
        console.log(error);
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleChildErrorsChange = (errors) => {
    setServerError(errors);
  };

  useEffect(() => {
    if (
      serverError &&
      dateWatcher &&
      (serverError.startsWith('Service is not available on weekends.') ||
        serverError.startsWith('Appointment already exists'))
    ) {
      clearErrors('date');
    }
  }, [dateWatcher, serverError]);

  useEffect(() => {
    if (
      serverError &&
      timeWatcher &&
      serverError.startsWith('Appointment time can not be before now')
    ) {
      clearErrors('time');
    }
  }, [timeWatcher, serverError]);

  return (
    <Card data-tid="appointment-page">
      <Grid container className={classes.header} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={4} sm={6} md={4} lg={3}></Grid>
        <Grid
          item
          xs={8}
          sm={6}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Add appointment</Typography>
          </Button>
        </Grid>
      </Grid>
      {dialogs.add && (
        <AppointmentPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onConfirm={() => handleDialogState({ add: false })}
          setError={setError}
          errors={errors}
          onErrorsChange={handleChildErrorsChange}
          control={control}
          serverError={serverError}
          register={register}
          handleSubmit={handleSubmit}
          dateWatcher={dateWatcher}
          timeWatcher={timeWatcher}
          addAppointment={addAppointment}
          setValue={setValue}
          field={field}
          reset={reset}
          durationInMinutes={durationInMinutes}
          tintGlass={tintGlass}
          setTintGlass={setTintGlass}
          additionalService={additionalService}
          setAdditionalService={setAdditionalService}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          totalDuration={totalDuration}
          setTotalDuration={setTotalDuration}
          isDirty={isDirty}
          validate={validate}
        />
      )}

      {dialogs.edit !== false && (
        <AppointmentPopup
          mode="edit"
          onConfirm={() => handleDialogState({ edit: false })}
          handleClose={() => handleDialogState({ edit: false })}
          appointmentState={dialogs.edit}
          durationInMinutes={durationInMinutes}
          setError={setError}
          errors={errors}
          control={control}
          serverError={serverError}
          register={register}
          handleSubmit={handleSubmit}
          dateWatcher={dateWatcher}
          timeWatcher={timeWatcher}
          updateAppointment={updateAppointment}
          field={field}
          reset={reset}
          setValue={setValue}
          tintGlass={tintGlass}
          setTintGlass={setTintGlass}
          additionalService={additionalService}
          setAdditionalService={setAdditionalService}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          totalDuration={totalDuration}
          setTotalDuration={setTotalDuration}
          isDirty={isDirty}
          validate={validate}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this appointment?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteAppointment}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="appointments-table">
        <Table>
          {appointments.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="tableTitle"> FULL NAME</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> EMAIL</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> PHONE NUMBER</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> DATE </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> TOTAL PRICE </Typography>
                </TableCell>

                {/* <TableCell>
                  <Typography variant="tableTitle"> BACK </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> FRONT </Typography>
                </TableCell> */}
                <TableCell>
                  <Typography variant="tableTitle"> ACTIONS </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {appointments.length ? (
              appointments?.map((value) => {
                return (
                  <TableRow hover className={classes.cursor} key={value?.id}>
                    <TableCell>
                      <Typography variant="text">
                        {`${value?.customer?.firstName} ${value?.customer?.lastName}`}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value?.customer?.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{`+${value?.customer?.phoneNumber}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{value?.appointmentTime}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{value.totalPrice} CHF</Typography>
                    </TableCell>

                    {/* <TableCell sx={{ maxWidth: '100px', overflow: 'hidden' }}>
                      <Typography variant="text">{value?.backPositionPercentage} % </Typography>
                    </TableCell> */}
                    {/* <TableCell sx={{ maxWidth: '100px', overflow: 'hidden' }}>
                      <Typography variant="text">{value?.frontPositionPercentage} %</Typography>
                    </TableCell> */}
                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="Edit"
                            onClick={(e) => handleDialogState({ edit: value })}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => handleDialogState({ delete: value?.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Empty />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default Appointments;
