import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import {
  LOAD_GALLERY,
  CREATE_GALLERY,
  GET_GALLERY,
  DELETE_GALLERY,
  UPDATE_GALLERY,
} from './actionType';

const initial = initialState.gallery;
const reducer = handleActions(
  {
    [LOAD_GALLERY]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [CREATE_GALLERY]: (state, { payload }) => {
      return {
        ...state,
        gallery: {
          ...state.images,
          data: [...[payload], ...state.images],
        },
        isLoad: false,
      };
    },
    [GET_GALLERY]: (state, { payload }) => {
      console.log(state);
      return {
        ...state,
        data: payload,
        isLoad: false,
      };
    },
    [DELETE_GALLERY]: (state, { payload }) => {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          data: state.gallery?.data.filter((item) => item?.id != payload),
        },
      };
    },
    [UPDATE_GALLERY]: (state, { payload }) => {
      // return {
      //   ...state,
      //   appointments: {
      //     ...state.appointments,
      //     data:
      //       state.appointments?.data.map((appointment) =>
      //         appointment?.id == payload?.id ? payload : appointment
      //       ) || [],
      //   },
      // };
    },
  },

  initial
);

export default reducer;
