import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grow,
  InputLabel,
  Grid,
  FormHelperText,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { backPosition, frontPosition } from 'utils/selectOptions';
import dayjs from 'dayjs';
import axios from 'axios';
import { ServiceTimePicker } from 'components';
import moment from 'moment';
import BasicCheckbox from 'components/BasicCheckbox';
import { PatternFormat } from 'react-number-format';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

export const AppointmentPopup = ({
  handleClose,
  mode,
  appointmentState,
  onConfirm,
  addAppointment,
  updateAppointment,
  isDirty,
  errors,
  control,
  timeWatcher,
  register,
  handleSubmit,
  dateWatcher,
  setValue,
  durationInMinutes,
  tintGlass,
  setTintGlass,
  reset,
  additionalService,
  setAdditionalService,
  selectedServices,
  setSelectedServices,
  totalDuration,
  setTotalDuration,
  serverError,
  setError,
  validate,
}) => {
  const classes = useStyles();
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(false);
  const [timeArray, setTimeArray] = useState([]);

  const today = dayjs();
  const selectedDate = useMemo(() => moment(dateWatcher, 'YYYY-MM-DD'), [dateWatcher]);
  const selectedTime = useMemo(() => moment(timeWatcher, 'HH:mm'), [timeWatcher]);

  const combinedDateTime = useMemo(() => {
    return selectedDate.set({
      hour: selectedTime.hour(),
      minute: selectedTime.minute(),
      second: 0,
    });
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`,
          {
            params: {
              page: 0,
              size: 500,
            },
          }
        );

        setTintGlass(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const getAdditionalService = () => {
      return axios.get(`${process.env.REACT_APP_API_KEY}coverbox/public/getAdditionalServiceDTO`, {
        params: {
          page: 0,
          size: 500,
        },
      });
    };

    const getAdditionalServiceById = (additionalServiceId) => {
      return axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getAdditionalServiceDTOById`,
        {
          params: {
            additionalServiceId,
          },
        }
      );
    };

    getAdditionalService()
      .then((response) => {
        const imageData = response.data;
        localStorage.setItem('selectedServices', JSON.stringify(imageData));
        const promises = imageData.map((item) => getAdditionalServiceById(item.id));
        return Promise.all(promises);
      })
      .then((responses) => {
        const imagesData = responses.map((response) => response.data);
        setAdditionalService(imagesData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (mode === 'edit' && appointmentState) {
      setSelectedServices(appointmentState?.additionalServiceIds);
    } else {
      // localStorage.removeItem('selectedServices');
      setSelectedServices([]);
      reset();
    }
  }, [mode, appointmentState]);

  const handleSelectChange = (event) => {
    const selectedValues = event.target.value;
    localStorage.getItem('selectedServices', JSON.stringify(selectedValues));
    setSelectedServices(selectedValues);

    let totalValue = selectedValues.reduce((total, serviceId) => {
      const service = additionalService.find((service) => service.id === serviceId);
      return service ? total + service?.durationInMinutes : total;
    }, 0);

    if (selectedValues.length > 0) {
      totalValue += durationInMinutes;
    } else {
      totalValue = durationInMinutes;
    }
    setTotalDuration(totalValue);
  };
  const constructTimeRanges = (startTime, endTime) => {
    return `${startTime} - ${endTime}`;
  };
  const parsedAppointmentTime = new Date(appointmentState?.appointmentTime);
  const appointmentTimeDate = new Date(parsedAppointmentTime);
  appointmentTimeDate?.setMinutes(
    appointmentTimeDate?.getMinutes() + (totalDuration ? totalDuration : durationInMinutes)
  );
  const startTime = moment(parsedAppointmentTime).format('HH:mm');
  const endTime = moment(parsedAppointmentTime)
    .add(totalDuration ? totalDuration : durationInMinutes, 'minutes')
    .format('HH:mm');
  const timeRange = constructTimeRanges(startTime, endTime);

  useEffect(() => {
    if (appointmentState) {
      const parsedAppointmentTime = new Date(appointmentState?.appointmentTime);
      const appointmentDate =
        parsedAppointmentTime && parsedAppointmentTime?.toISOString()?.split('T')[0];
      const appointmentTime =
        parsedAppointmentTime && parsedAppointmentTime?.toTimeString()?.split(' ')[0];
      const appointmentTimeDate = new Date(parsedAppointmentTime);
      appointmentTimeDate?.setMinutes(
        appointmentTimeDate?.getMinutes() + (totalDuration ? totalDuration : durationInMinutes)
      );

      const appointmentTimeWithDuration = appointmentTimeDate?.toTimeString()?.split(' ')[0];
      const startTime = appointmentTime.trim().slice(0, 5);
      const endTime = appointmentTimeWithDuration.slice(0, 5);
      const timeRange = `${startTime} - ${endTime}`;
      setValue('firstName', appointmentState?.customer?.firstName || '');
      setValue('lastName', appointmentState?.customer?.lastName || '');
      setValue('email', appointmentState?.customer?.email || '');
      setValue('phoneNumber', appointmentState?.customer?.phoneNumber || '');
      setValue('tintGlassId', appointmentState?.tintGlassId || '');
      setValue('additionalServiceIds', selectedServices || []);
      // setValue('frontPositionPercentage', appointmentState?.frontPositionPercentage || '');
      // setValue('backPositionPercentage', appointmentState?.backPositionPercentage || '');
      setValue('date', appointmentDate || '');
      setValue('enabled', appointmentState.enabled ? false : true);
      setValue('time', timeRange);
      setValue('durationInMinutes', appointmentState?.durationInMinutes || 0);
    }
  }, [appointmentState, setValue, totalDuration, durationInMinutes]);

  useEffect(() => {
    if (
      serverError &&
      (serverError.startsWith('Service is not available on weekends.') ||
        serverError.startsWith('Appointment already exists'))
    ) {
      setError('date', {
        type: 'custome',
        message: serverError,
      });
    }
  }, [serverError]);

  useEffect(() => {
    if (
      serverError &&
      (serverError.startsWith('Appointment time can not be before now') ||
        serverError.startsWith('Appointment time can not be before now'))
    ) {
      setError('time', {
        type: 'custome',
        message: serverError,
      });
    }
  }, [serverError]);

  function hasTimeError() {
    if (
      (serverError && serverError.startsWith('Appointment time can not be before now')) ||
      serverError === 'Appointment time can not be before now'
    ) {
      return 'time error';
    }
  }

  function hasDateError() {
    if (
      (serverError && serverError.startsWith('Appointment already exists')) ||
      serverError === 'Service is not available on weekends.'
    ) {
      return 'date error';
    }
  }

  // useEffect(() => {
  //   const getAllAppointmentsByDate = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_KEY}coverbox/public/appointment/getAllAppointmentsByDate`,
  //         {
  //           params: {
  //             year: selectedDate.format('YYYY'),
  //             month: selectedDate.format('MM'),
  //             day: selectedDate.format('DD'),
  //             // tintglassService:
  //             //   tintGlassId || '' ? tintGlassId : state?.tintedGlass?.id,
  //             ...selectedServices.reduce((acc, service) => {
  //               acc[`additionalServices`] = service;
  //               return acc;
  //             }, {}),
  //           },
  //         }
  //       );
  //       const data = response.data;
  //       setRegisteredAppoint([...data]);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getAllAppointmentsByDate();
  // }, [selectedDate, selectedServices]);

  const onSubmit = async (data) => {
    if (mode === 'add') {
      setIsCreatingAppointment(true);
      try {
        // const validateResult = await hasTimeError();
        if (!serverError) {
          await addAppointment(data);
        }

        onConfirm(appointmentState?.id);
        reset();
        setSelectedServices([]);
        handleClose();
      } catch (error) {
        console.error('Error in onSubmit (add):', error);
      } finally {
        setIsCreatingAppointment(false);
      }
    }

    if (mode === 'edit') {
      setIsCreatingAppointment(true);
      setSelectedServices(selectedServices || []);
      try {
        await updateAppointment(appointmentState?.id, data);

        onConfirm(appointmentState?.id);
        handleClose();
      } catch (error) {
        console.error('Error in onSubmit (edit):', error);
      } finally {
        setIsCreatingAppointment(false);
      }
    }
  };

  const getAllAvailableAppointmentDates = async () => {
    const additionalServicesIds = selectedServices
      .map((service) => `additionalServicesIds=${service}`)
      .join('&');
    try {
      const additionalServicesIds = selectedServices
        .map((service) => `additionalServicesIds=${service}`)
        .join('&');

      const url = `${
        process.env.REACT_APP_API_KEY
      }coverbox/public/appointment/getAllAvailableAppointmentDates?year=${selectedDate.format(
        'YYYY'
      )}&month=${selectedDate.format('MM')}&day=${selectedDate.format(
        'DD'
      )}&${additionalServicesIds}`;

      const response = await axios.get(url);
      const data = response.data;
      setTimeArray([...data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAvailableAppointmentDates();
  }, [selectedDate, selectedServices]);

  const handlePhoneChange = (formattedValue) => {
    if (typeof formattedValue === 'string') {
      // Update the form state with the formatted phone number
      setValue('phoneNumber', formattedValue);
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode === 'add' ? 'Add new appointment' : 'Edit appointment'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent>
        <Box className={classes.container} autoComplete="off">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    type="text"
                    fullWidth
                    {...register('firstName')}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    type="text"
                    fullWidth
                    {...register('lastName')}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    fullWidth
                    type="email"
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* <TextField
                    label="Phone"
                    type="number"
                    fullWidth
                    {...register('phoneNumber')}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                  /> */}
                  <Controller
                    name="phoneNumber" // make sure this matches the name in register
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        label="Phone"
                        type="tel"
                        fullWidth
                        {...field}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                        className={classes.textfield}
                        InputProps={{
                          inputComponent: PatternFormat,
                          inputProps: {
                            type: 'tel',
                            format: '+41 ## ### ## ##',
                            mask: '_',
                            onValueChange: handlePhoneChange,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            sx={{ width: '100%!important' }}
                            {...field}
                            fullWidth
                            label="Date"
                            id="date"
                            {...register('date')}
                            error={!!errors.date}
                            helperText={errors.date?.message}
                            value={dayjs(dateWatcher)}
                            minDate={today}
                            shouldDisableDate={(date) => {
                              // Convert the date to a dayjs object to work with it
                              const dayjsDate = dayjs(date);

                              // Check if the day of the week is a weekend (0 = Sunday, 6 = Saturday)
                              const dayOfWeek = dayjsDate?.day();
                              return dayOfWeek === 0 || dayOfWeek === 6;
                            }}
                            onChange={(newValue) => setValue('date', newValue?.toDate())}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}
                  />
                  {hasDateError() && <span className={classes.error}>{errors.date?.message}</span>}{' '}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.additionalServiceIds}>
                    <InputLabel id="additionalServiceIds-select-label" color="secondary">
                      Additional Services
                    </InputLabel>
                    <Controller
                      name="additionalServiceIds"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          multiple
                          labelId="additionalServiceIds-select-label"
                          label="Additional ServiceS"
                          variant="outlined"
                          color="secondary"
                          value={selectedServices}
                          onChange={handleSelectChange} // Handle the Select
                        >
                          {additionalService.map((value) => (
                            <MenuItem
                              key={value.id}
                              value={value.id}
                              className={selectedServices.includes(value.id) ? classes.grey : ''}>
                              {value.description_en}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.additionalServiceIds && (
                      <FormHelperText>{errors.additionalServiceIds.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <ServiceTimePicker
                    control={control}
                    duration={totalDuration !== 0 ? totalDuration : durationInMinutes}
                    register={register}
                    errors={errors}
                    validate={serverError}
                    timeRange={timeRange}
                    appointedTime={appointmentState}
                    time={timeArray}
                    mode={mode}
                    selectedDate={selectedDate}
                  />
                  {hasTimeError() && <span className={classes.error}>{errors.time?.message}</span>}
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    className={classes.percentageField}
                    fullWidth
                    error={!!errors.tintGlassId}>
                    <InputLabel id="tintGlassId" color="secondary">
                      Car Model
                    </InputLabel>
                    <Controller
                      name="tintGlassId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          IconComponent={KeyboardArrowDownIcon}
                          className={classes.select}
                          {...field}
                          labelId="tintGlassId"
                          label="Car Model"
                          variant="outlined"
                          color="secondary">
                          {Object.entries(tintGlass).map(([key, value]) => (
                            <MenuItem key={key} value={value.id}>
                              {value?.model_en}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.tintGlassId && (
                      <FormHelperText>{errors.tintGlassId.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.frontPositionPercentage}>
                    <InputLabel id="front-select-label" color="secondary">
                      Front Position
                    </InputLabel>
                    <Controller
                      name="frontPositionPercentage"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="front-select-label"
                          label="Front Position"
                          variant="outlined"
                          color="secondary">
                          {Object.entries(frontPosition).map(([key, value]) => (
                            <MenuItem key={key} value={parseInt(key, 10)}>
                              {value}%
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.frontPositionPercentage && (
                      <FormHelperText>{errors.frontPositionPercentage.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.backPositionPercentage}>
                    <InputLabel id="back-select-label" color="secondary">
                      Back Position
                    </InputLabel>
                    <Controller
                      name="backPositionPercentage"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="back-select-label"
                          label="Back Position"
                          variant="outlined"
                          color="secondary">
                          {Object.entries(backPosition).map(([key, value]) => (
                            <MenuItem key={key} value={parseInt(key, 10)}>
                              {value}%
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.backPositionPercentage && (
                      <FormHelperText>{errors.backPositionPercentage.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid> */}
                {mode === 'edit' && (
                  <Grid item xs={6}>
                    <Controller
                      name="enabled"
                      control={control}
                      render={({ field }) => (
                        <BasicCheckbox
                          {...field}
                          label="Enabled"
                          checked={field.value}
                          // onChange={(newValue) => setValue('enabled', newValue ? true : false)}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box className={classes.buttonWrapper}>
              <Button
                size="small"
                variant="contained"
                className={classes.cancel}
                onClick={handleClose}>
                <Typography variant="subtitle3">Cancel</Typography>
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isDirty && mode === 'edit'} // Disable if there are changes and it's in edit mode
              >
                <Typography variant="subtitle4">{mode === 'add' ? 'Create' : 'Edit'}</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentPopup;
