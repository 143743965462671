import React, { forwardRef, useEffect, useState } from 'react';
import { Typography, Button, Dialog, DialogContent, Divider, Box, Grow } from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FileUpload from 'components/ImageUpload';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const GalleryPopup = ({
  handleClose,
  galleryState,
  onConfirm,
  mode,
  addGallery,
  updateGallery,
  uploadedFile,
  setUploadedFile,
}) => {
  const classes = useStyles();
  // const [uploadedFile, setUploadedFile] = useState(null);

  // const handleFileUpload = (fileData) => {
  //   setUploadedFile(fileData);
  // };
  // const [state, setState] = useState({
  //   imageType: 'OUR_GALLERY',
  //   images: [],
  //   ...galleryState,
  // });

  const sendForm = () => {
    if (mode == 'add') {
      addGallery();
    } else {
      updateGallery(galleryState);
    }

    if (onConfirm) {
      onConfirm(galleryState);
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode === 'add' ? 'Add image to Gallery' : 'Edit image in Gallery'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>
      <Divider />
      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <FileUpload
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                imageId={galleryState}
              />
            </Grid>
          </Box>
          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={sendForm}
              // disabled={!uploadedFile}
            >
              <Typography variant="subtitle4">{mode === 'add' ? 'Create' : 'Edit'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GalleryPopup;
