import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '25px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px',
    padding: '8px',
  },

  button: {
    height: '40px',
    cursor: 'pointer',
  },
}));
