import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  logo: {
    width: '150px',
    marginBottom: '25px',
  },

  form: {
    maxWidth: '400px',
    width: '100%',
    // borderRadius: '40px',
    padding: '45px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // boxShadow:
    //   '0px 2px 1px -1px rgb(0 0 0 / 38%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 28%) !important',

    [theme.breakpoints.down(360)]: {
      width: '280px !important',
    },
  },

  textField: {
    background: '#f5f8fa',
    width: '320px !important',
    margin: '15px!important',
    [theme.breakpoints.down(460)]: {
      width: '250px !important',
    },
  },

  textAttribute: {
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    textDecoration: 'none !important',
    color: 'black !important',
    '&:hover': {
      opacity: 0.9,
    },
  },

  linkedText: {
    textDecoration: 'none !important',
    [theme.breakpoints.down(460)]: {
      fontSize: '14px !important',
    },
  },

  continueBtn: {
    marginBottom: '20px !important',
    maxWidth: '400px',
    width: '100%',
    height: '45px',
    background: '#1A1C3A!important',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '15px',
    width: '100%',
    [theme.breakpoints.down(460)]: { justifyContent: 'space-between' },
  },
  submit: {
    width: '100%',
    maxWidth: '100px',
    height: '40px',
  },
  cancel: {
    width: '100%',
    maxWidth: '100px',
    height: '40px',
    background: `${theme.palette.custom.lightGray} !important`,
  },
}));
