import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';
import Cookies from 'universal-cookie';

const Profile = () => {
  const cookies = new Cookies();
  const [queries] = useSearchParams();
  const [serverErrors, setServerErrors] = useState(null);
  const classes = useStyles();

  const [state, setState] = useState({
    email: '',
    oldPassword: '',
    newPassword: '',
  });

  const [response, serResponse] = useState([]);

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}coverbox/management/admin/resetPassword`,

        {
          email: state.email,
          newPassword: state.newPassword,
          oldPassword: state.oldPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );
      serResponse(response.data);
    } catch (error) {
      console.error(error);
      setServerErrors(error.response?.data);
    }
  };

  return (
    <>
      <Box className={classes.form} autoComplete="off">
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="p" className={classes.textAttribute}>
              Reset Password
            </Typography>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="email"
                value={state.email}
                onChange={(e) => setStateValue('email', e.target.value)}
                label="Email"
                color="secondary"
                className={classes.textField}
                // onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="password"
                value={state.oldPassword}
                onChange={(e) => setStateValue('oldPassword', e.target.value)}
                label="Old Password"
                color="secondary"
                className={classes.textField}
                // onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="password"
                value={state.newPassword}
                onChange={(e) => setStateValue('newPassword', e.target.value)}
                label="New Password"
                color="secondary"
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.btnContainer}>
                <Button size="small" className={classes.submit} variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
