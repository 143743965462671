import React, { forwardRef, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grow,
  InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import FileUpload from 'components/ImageUpload';
import { TintedGlass } from 'pages';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const GalleryPopup = ({
  handleClose,
  tintState,
  onConfirm,
  mode,
  uploadedFile,
  setUploadedFile,
  addGallery,
  updateGallery,
}) => {
  const classes = useStyles();

  const sendForm = () => {
    if (mode === 'add') {
      addGallery();
    } else {
      updateGallery(tintState);
    }

    if (onConfirm) {
      onConfirm(tintState);
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode == 'add' ? 'Add image to Gallery' : 'Edit image to Gallery'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <FileUpload
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                imageId={tintState}
              />
            </Grid>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button
              // disabled={isSaveDisabled}
              size="small"
              variant="contained"
              color="primary"
              onClick={sendForm}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Edit'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GalleryPopup;
