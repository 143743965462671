import React, { useState } from 'react';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import { Card, Divider, Typography } from '@mui/material';
import { CarWindowTint, Partners, SpecialCars } from '..';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/BasicTabs/TabPanel';
import Gallery from './Tabs/Gallery';

const TintedGlassComponent = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const setTabValue = (idx) => {
    setCurrentTab(idx);
  };

  return (
    <>
      <Card className={classes.card}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="subtitle1">
            Tinted Glass
          </Typography>
        </Box>
        <Divider />

        <Tabs
          value={currentTab}
          onChange={(e, idx) => setTabValue(idx)}
          aria-label="secondary tabs example"
          variant={'scrollable'}
          // variant="fullWidth"
          scrollButtons={'auto'}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            flexShrink: 0,
            overflowX: 'auto !important',
            overflowY: 'hidden !important',
          }}>
          <Tab variant="text" label="Our Partners" />
          <Tab variant="text" label="Gallery" />
          <Tab variant="text" label="Our Rates & Special Cars" />
          <Tab variant="text" label="Additional Services" />
        </Tabs>
        <Divider />

        <Box>
          <TabPanel value={currentTab} index={0}>
            <Partners />
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <Gallery />
          </TabPanel>

          <TabPanel value={currentTab} index={2}>
            <SpecialCars />
          </TabPanel>

          <TabPanel value={currentTab} index={3}>
            <CarWindowTint />
          </TabPanel>
        </Box>
      </Card>
    </>
  );
};

export default TintedGlassComponent;
