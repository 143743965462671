// import React, { useEffect, useState } from 'react';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import './styles.css';
// import Image from 'components/ImageComponent';
// import { mode } from 'utils/mode';

// const FileUpload = ({
//   uploadedFile,
//   setUploadedFile,
//   partnerId,
//   imageId,
//   specialCarId,
//   customerSayId,
//   additionalService,
// }) => {
//   const [fileName, setFileName] = useState('No selected file');
//   const [imageIds, setImageId] = useState(null);

//   useEffect(() => {
//     if (imageId && imageId.length > 0) {
//       setFileName('Selected Image');
//       setImageId(imageId);
//     } else if (partnerId && partnerId.length > 0) {
//       setFileName('Selected Image');
//       setImageId(partnerId);
//     } else if (customerSayId) {
//       setFileName('Selected Image');
//       setImageId(customerSayId?.id);
//     }
//   }, [imageId, customerSayId, partnerId]);

//   const handleDrop = (e) => {
//     e.preventDefault();
//     const file = e.dataTransfer.files[0];
//     setFileName(file.name);
//     setUploadedFile(file);
//     setImageId(null);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFileName(file.name);
//     setUploadedFile(file);
//     setImageId(null);
//   };

//   const handleDelete = () => {
//     setFileName('No selected file');
//     setUploadedFile(null);
//     setImageId(null);
//   };

//   return (
//     <main className="uploaded-main">
//       <form
//         className="uploaded-form"
//         onClick={() => document.querySelector('.input-field').click()}
//         onDrop={handleDrop}
//         onDragOver={handleDragOver}>
//         <input
//           type="file"
//           accept="image/*"
//           className="input-field"
//           hidden
//           onChange={handleFileChange}
//         />

//         {uploadedFile ? (
//           <img src={URL?.createObjectURL(uploadedFile)} width={300} height={400} alt={fileName} />
//         ) : (
//           <>
//             {imageId ? (
//               <Image id={imageId} mode={mode.images} />
//             ) : partnerId ? (
//               <Image id={partnerId} mode={mode.partners} />
//             ) : specialCarId ? (
//               <Image id={specialCarId} mode={mode.specialCars} />
//             ) : (
//               <Image id={customerSayId?.id} mode={mode.customerSayId} />
//             )}
//             <CloudUploadIcon sx={{ fontSize: '60px' }} />
//             <p>Drag and drop file here or browse file to upload</p>
//           </>
//         )}
//       </form>

//       <section className="uploaded-row">
//         <InsertDriveFileIcon />
//         <span className="uploaded-content">
//           {fileName}
//           <DeleteForeverIcon onClick={handleDelete} />
//         </span>
//       </section>
//     </main>
//   );
// };

// export default FileUpload;
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './styles.css';
import Image from 'components/ImageComponent';
import { mode } from 'utils/mode';

const FileUpload = ({
  uploadedFile,
  setUploadedFile,
  partnerId,
  imageId,
  specialCarId,
  customerSayId,
  additionalService,
}) => {
  const [fileName, setFileName] = useState('No selected file');
  const [fileUrl, setFileUrl] = useState(null);
  const [imageIds, setImageId] = useState(null);

  useEffect(() => {
    if (imageId && imageId.length > 0) {
      setFileName('Selected Image');
      setImageId(imageId);
    } else if (partnerId && partnerId.length > 0) {
      setFileName('Selected Image');
      setImageId(partnerId);
    } else if (customerSayId) {
      setFileName('Selected Image');
      setImageId(customerSayId?.id);
    }
  }, [imageId, customerSayId, partnerId, specialCarId]);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setFileName(file.name);
    setUploadedFile(file);
    setImageId(null);

    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      setFileUrl(dataUrl);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setUploadedFile(file);
    setImageId(null);

    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      setFileUrl(dataUrl);
    };
    reader.readAsDataURL(file);
  };

  const handleDelete = () => {
    setFileName('No selected file');
    setUploadedFile(null);
    setImageId(null);
    setFileUrl(null);
  };

  return (
    <main className="uploaded-main">
      <form
        className="uploaded-form"
        onClick={() => document.querySelector('.input-field').click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}>
        <input
          type="file"
          accept="image/*"
          className="input-field"
          hidden
          onChange={handleFileChange}
        />

        {fileUrl ? (
          <img
            src={fileUrl}
            width={300}
            height={400}
            style={{ width: '100%', height: '100%' }}
            alt={fileName}
          />
        ) : (
          <>
            {imageId ? (
              <Image id={imageId} mode={mode.images} />
            ) : partnerId ? (
              <Image id={partnerId} mode={mode.partners} />
            ) : specialCarId ? (
              <Image id={specialCarId} mode={mode.specialCars} />
            ) : (
              <Image id={customerSayId?.id} mode={mode.customerSayId} />
            )}
            <CloudUploadIcon sx={{ fontSize: '60px' }} />
            <p>Drag and drop or browse file</p>
          </>
        )}
      </form>

      <section className="uploaded-row">
        <InsertDriveFileIcon />
        <span className="uploaded-content">
          {fileName}
          <DeleteForeverIcon onClick={handleDelete} />
        </span>
      </section>
    </main>
  );
};

export default FileUpload;
