import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  paginator: {
    margin: '20px',
    marginLeft: 'auto',
  },
}));
