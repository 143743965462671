import React from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';
import Profile from 'components/profile/Profile';
import { useStyles } from './styles';

const MyProfile = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box className={classes.header}>
        <Typography className={classes.title} variant="subtitle1">
          Profile
        </Typography>
      </Box>
      <Divider />
      <Profile />
    </Card>
  );
};

export default MyProfile;
