import React from 'react';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import '../../themes/app.scss';

import { FormControl, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useStyles } from './styles';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  })
);

export default function CustomDateRangePickerDay({ value, onChange, clear }) {
  // const [value, setValue] = useState([null, null]);
  const classes = useStyles();
  const renderWeekPickerDay = (date, dateRangePickerDayProps) => {
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
  };

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            label="Advanced keyboard"
            value={value}
            onChange={onChange}
            renderInput={(startProps, endProps) => (
              <Grid container spacing={{ xs: 1 }} className={classes.gridContainer}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="start" color="secondary">
                      Date Start
                    </InputLabel>
                    <OutlinedInput
                      color="secondary"
                      label="date start"
                      ref={startProps.inputRef}
                      {...startProps.inputProps}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item className={classes.hide}>
                  <EastIcon />
                </Grid> */}

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="end" color="secondary">
                      Date End
                    </InputLabel>
                    <OutlinedInput
                      color="secondary"
                      label="date end"
                      ref={endProps.inputRef}
                      {...endProps.inputProps}
                    />
                  </FormControl>
                </Grid>

                {/* <Box>
                  <Tooltip title="clear">
                    <IconButton aria-label="clear" onClick={clear}>
                      <ClearOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box> */}
              </Grid>
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

{
  /* <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            label="date range"
            value={value}
            onChange={onChange}
            renderDay={renderWeekPickerDay}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider> */
}
