import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import SpecialCarsPopup from './Dialog/SpecialCarsPopup';
import axios from 'axios';
import uuid from 'react-uuid';
import Image from 'components/ImageComponent';
import { mode } from 'utils/mode';
import { carTintSchema } from 'utils/formValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Empty } from 'antd';
import Cookies from 'universal-cookie';

const SpecialCars = () => {
  const cookies = new Cookies();

  const classes = useStyles();
  const [imageIds, setImageIds] = useState([]);
  const [data, setData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [passengersCount, setPassengersCount] = useState(0);
  const [serverError, setServerError] = useState('');
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    control,
    setValue,
    formState: { errors, isDirty },
    reset,
    field,
  } = useForm({
    resolver: yupResolver(carTintSchema),
    defaultValues: {
      model_en: '',
      model_fr: '',
      price: 0,
      discountPrice: 0,
      isSpecial: false,
    },
  });

  const model_en = watch('model_en');
  const model_fr = watch('model_fr');

  const handleFileUpload = (fileData) => {
    setUploadedFile(fileData);
  };

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };

  const deleteUserAction = async (tintGlassServiceId) => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      await axios.delete(
        `${process.env.REACT_APP_API_KEY}coverbox/management/tintglass/delete?tintGlassServiceId=${tintGlassServiceId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      localStorage.removeItem('specialcars');
      const updatedImageIds = imageIds.filter((specialCar) => specialCar.id !== specialCar);
      setImageIds(updatedImageIds);
      handleDialogState({ delete: false });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTintGlassService = () => {
    return axios.get(`${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`, {
      params: {
        page: controller.page,
        size: controller.rowsPerPage,
      },
    });
  };

  const getTintGlassServiceDTOById = (tintGlassServiceId) => {
    return axios.get(`${process.env.REACT_APP_API_KEY}coverbox/public/getTintGlassServiceDTOById`, {
      params: {
        tintGlassServiceId,
      },
    });
  };

  useEffect(() => {
    getAllTintGlassService()
      .then((response) => {
        const imageData = response.data;
        localStorage.setItem('specialcars', JSON.stringify(imageData));
        const promises = imageData.map((item) => getTintGlassServiceDTOById(item.id));
        return Promise.all(promises);
      })
      .then((responses) => {
        const imagesData = responses.map((response) => response.data);
        setData(imagesData);
        setPassengersCount(imagesData.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [controller, uploadedFile, imageIds]);

  const addCarTint = async (data) => {
    const formData = new FormData();
    formData.append('model_en', data.model_en);
    formData.append('model_fr', data.model_fr);
    formData.append('price', data.price);
    formData.append('discountPrice', data.discountPrice);
    formData.append('isSpecial', data.isSpecial ? true : false);
    formData.append('durationInMinutes', 60);
    formData.append('image', uploadedFile);
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}coverbox/management/tintglass/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-XSRF-TOKEN': xsrfToken,
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      if (response.status === 200) {
        setServerError('');

        handleFileUpload(response.data);
        setImageIds((prevState) => {
          const updatedCar = [...prevState];
          updatedCar.push(data);
          return updatedCar;
        });
        localStorage.setItem('specialcars', JSON.stringify(response.data));
      }
    } catch (error) {
      if (error.response) {
        setError('model_en', { type: 'custom', message: 'Car models cannot be duplicated' });
        setError('model_fr', { type: 'custom', message: 'car models cannot be duplicated' });

        setServerError(error.response.data.message);
      }
    }
  };

  const updateCarTint = async (id, data) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('model_en', data.model_en);
    formData.append('model_fr', data.model_fr);
    formData.append('price', data.price);
    formData.append('discountPrice', data.discountPrice);
    formData.append('isSpecial', data.isSpecial ? true : false);
    formData.append('tintGlassServiceId', id);
    formData.append('durationInMinutes', 60);
    formData.append('image', uploadedFile);

    const response = await axios.put(
      `${process.env.REACT_APP_API_KEY}coverbox/management/tintglass/update`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,

          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    if (response.status === 200) {
      // handleClose();
      localStorage.setItem('specialcars', JSON.stringify(response.data));
      const tinted = response.data;

      setImageIds((prevState) => {
        const updatedTint = prevState.map((item) => {
          if (item.id === tinted.id) {
            return { ...item, ...tinted };
          }
          return item;
        });
        return updatedTint;
      });
      setValue('model_en', tinted.model_en);
      setValue('model_fr', tinted.model_fr);
      setValue('price', tinted.price);
      setValue('discountPrice', tinted.discountPrice);
      setValue('isSpecial', tinted.isSpecial);
      setValue('newImage', tinted.id);
    }
  };

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  function calculateDiscountPrice(originalPrice, discountPercentage) {
    const discountAmount = (originalPrice * discountPercentage) / 100;
    const finalPrice = originalPrice - discountAmount;
    return finalPrice % 1 === 0 ? finalPrice.toFixed(0) : finalPrice.toFixed(2);
  }
  const totalPages = controller;
  return (
    <Card data-tid="staff-page">
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.header}>
        <Grid item xs={5} sm={7} md={4} lg={3}></Grid>
        <Grid
          item
          xs={7}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button variant="contained" onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Upload image</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <SpecialCarsPopup
          mode="add"
          handleSubmit={handleSubmit}
          handleClose={() => handleDialogState({ add: false })}
          onConfirm={() => handleDialogState({ add: false })}
          uploadedFile={uploadedFile}
          tintedState={dialogs.add}
          setUploadedFile={setUploadedFile}
          register={register}
          setValue={setValue}
          errors={errors}
          addCarTint={addCarTint}
          reset={reset}
          watch={watch}
          control={control}
          isDirty={isDirty}
          setError={setError}
          model_en={model_en}
          model_fr={model_fr}
          serverError={serverError}
          setServerError={serverError}
        />
      )}

      {dialogs.edit !== false && (
        <SpecialCarsPopup
          mode="edit"
          handleSubmit={handleSubmit}
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          tintedState={dialogs.edit}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          register={register}
          setValue={setValue}
          errors={errors}
          updateCarTint={updateCarTint}
          reset={reset}
          watch={watch}
          control={control}
          isDirty={isDirty}
          setError={setError}
          model_en={model_en}
          model_fr={model_fr}
          serverError={serverError}
          setServerError={serverError}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this image?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteUserAction}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          {data.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="tableTitle"> Model </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="tableTitle"> Price </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="tableTitle"> Image </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> Special </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> ACTIONS</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.length ? (
              data.map((item) => {
                const key = uuid();
                return (
                  <TableRow hover key={key} className={classes.cursor}>
                    <TableCell>
                      <Typography variant="text">{item.model_en}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">
                        {item.discountPrice
                          ? calculateDiscountPrice(item?.price, item?.discountPrice)
                          : item.price}{' '}
                        CHF
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Image id={item.id} mode={mode.specialCars} />
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">
                        {item.isSpecial ? <CheckIcon /> : <ClearIcon />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="Edit"
                            onClick={(e) => handleDialogState({ edit: item }, e)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => handleDialogState({ delete: item.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Empty />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default SpecialCars;
