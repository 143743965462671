import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthRouter } from '../routes';
import { GlobalAppRouter } from '../../config/routerConfig';
import { selectUser } from 'store/common/auth/selector';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component }) => {
  const { pathname } = useLocation();
  const role = 'admin';
  // const { user } = useSelector(selectUser);
  const user = localStorage.getItem('userId');
  console.log(user, 'private');

  if (!user) {
    return <Navigate to={AuthRouter?.paths?.login} state={{ from: pathname }} replace />;
  } else if (pathname === '/' && role) {
    return <Navigate to={GlobalAppRouter[role]?.paths?.base} state={{ from: pathname }} replace />;
  }

  return component;
};
