import { Box } from '@mui/material';
import React from 'react';
import { LoginForm } from '../../../components/forms/index.js';
import { useStyles } from './styles.js';
import loginBackground from '../../../assets/images/logo.svg';

const Login = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container} data-tid="login-page">
      <Box className={classes.leftContent}>
        <img src={loginBackground} alt="" style={{ width: '450px', height: '450px' }} />
      </Box>
      <Box className={classes.rightContent}>
        <LoginForm />
      </Box>
    </Box>
  );
};

export default Login;
