import { ManagerRouter, AdminRouter, UserRouter } from '../router/routes';

const roles = ['admin', 'manager', 'user'];

export const GlobalAppRouter = {
  admin: AdminRouter,
  manager: ManagerRouter,
  user: UserRouter,
};

export const GetAppGlobalRoutes = (role) => {
  if (roles.includes(role)) {
    return GlobalAppRouter[role].routes;
  }
  return [];
};
