import React, { useState } from 'react';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import { Card, Divider, Typography } from '@mui/material';
import { AfterEventSms } from '..';

const SettingsGrid = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="subtitle1">
            Settings
          </Typography>
        </Box>
        <Divider />
        <AfterEventSms />
      </Card>
    </>
  );
};

export default SettingsGrid;
