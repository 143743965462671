import axios from 'axios';
import { history } from 'utils/history';
import { COOKIE_API } from './api_routes';

// Setup Axios Instance
const axiosInstance = axios.create({
  baseURL: 'https://arscustom.ch:8443/',
  withCredentials: true,
});

// Axios Response code checker
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      history.push('/login');
    }
    return Promise.reject(error);
  }
);

// Requests handler
async function sendRequest(url, params, method = 'GET', isForm = false, config = {}) {
  try {
    let headers = { 'Content-Type': 'application/json' };
    if (isForm) {
      // headers['Content-Type'] = 'multipart/form-data';
    }

    let response = null;
    switch (method) {
      case 'GET_CACHED':
        response = await axiosInstance.get(url, { params: { ...params } }, { headers, ...config });
        break;

      case 'GET':
        response = await axiosInstance.get(
          url,
          { params: { ...params, _t: Date.now() } },
          { headers, ...config }
        );
        break;
      case 'POST':
        response = await axiosInstance.post(url, params, { headers, ...config });
        break;
      case 'PUT':
      case 'PATCH':
      case 'DELETE':
        {
          let data;
          if (isForm) {
            params.append('_method', method);
            data = params;
          } else {
            data = { ...params, _method: method };
          }
          response = await axiosInstance.post(url, data, { headers, ...config });
        }
        break;
    }

    if (response) {
      return {
        ...response.data,
        statusCode: response.status,
      };
    }

    return {
      statusCode: 404,
    };
  } catch (error) {
    if (error.response?.status == 422 && typeof error.config.onErrors === 'function') {
      error.config.onErrors(error.response?.data?.errors || {});
    }

    return {
      status: false,
      ...error.response?.data,
      statusCode: error.response?.status,
    };
  }
}

// Shorthand Object for fast axios calls
const api = {
  async get(url, params, config = {}) {
    return sendRequest(url, params, 'GET', false, config);
  },

  async getCached(url, params, config = {}) {
    return sendRequest(url, params, 'GET_CACHED', false, config);
  },

  async post(url, params, config = {}) {
    return sendRequest(url, params, 'POST', false, config);
  },

  async put(url, params, config = {}) {
    return sendRequest(url, params, 'PUT', false, config);
  },

  async patch(url, params, config = {}) {
    return sendRequest(url, params, 'PATCH', false, config);
  },

  async delete(url, params, config = {}) {
    return sendRequest(url, params, 'DELETE', false, config);
  },

  async postForm(url, params, config = {}) {
    return sendRequest(url, params, 'POST', true, config);
  },

  async putForm(url, params, config = {}) {
    return sendRequest(url, params, 'PUT', true, config);
  },

  async patchForm(url, params, config = {}) {
    return sendRequest(url, params, 'PATCH', true, config);
  },
};

export default api;
