import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { Empty } from 'antd';
import ProcessingPopup from './Dialog/ProcessingPopup';
import axios from 'axios';
import Image from 'components/ImageComponent';
import { mode } from 'utils/mode';
import { UploadFile } from '@mui/icons-material';
import Cookies from 'universal-cookie';

const ProcessingTime = () => {
  const cookies = new Cookies();

  const classes = useStyles();
  const [imageIds, setImageIds] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const [state, setState] = useState({
    imageType: 'PROCESSING',
    images: [],
  });

  const handleFileUpload = (fileData) => {
    setUploadedFile(fileData);
  };

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };

  const deleteUserAction = async (imageId) => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      await axios.delete(
        `${process.env.REACT_APP_API_KEY}coverbox/management/gallery/delete?imageId=${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      localStorage.removeItem('processing');
      setImageIds((prevImageIds) => prevImageIds.filter((id) => id !== imageId));
      handleDialogState({ delete: false });
    } catch (error) {
      console.log(error);
    }

    handleDialogState({ delete: false });
  };

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getImageIdsByImageType`,
        {
          params: {
            imageType: 'PROCESSING',
          },
        }
      );

      const imageIds = response.data;
      localStorage.setItem('processing', JSON.stringify(imageIds));
      setImageIds(imageIds);
    } catch (error) {
      console.log(error);
    }
  };

  const addProcessing = async () => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('imageType', state.imageType);
    formData.append('images', uploadedFile);
    const response = await axios.post(
      `${process.env.REACT_APP_API_KEY}coverbox/management/gallery/create`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    if (response.status === 200) {
      handleFileUpload(response.data);
      // handleClose();
      setImageIds((prevGalleryItems) => [...prevGalleryItems, response.data]);

      localStorage.setItem('processing', JSON.stringify(response.data));
    }
  };

  const updateProcessing = async (id) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('imageType', state.imageType);
    formData.append('imageId', id);
    formData.append('newImage', uploadedFile);
    const response = await axios.put(
      `${process.env.REACT_APP_API_KEY}coverbox/management/gallery/update`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    if (response.status === 200) {
      // handleClose();
      localStorage.setItem('processing', JSON.stringify(response.data));
      const updatedImageIds = imageIds.map((imageId) =>
        imageId === id ? fetchImageById() : imageId
      );
      setImageIds(updatedImageIds);
    }
  };

  useEffect(() => {
    fetchImageById();
  }, [uploadedFile]);

  return (
    <Card data-tid="staff-page">
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.header}>
        <Grid item xs={5} sm={7} md={4} lg={3}>
          {/* <BasicSearch value={searchQuery} onChange={setSearchQuery} /> */}
        </Grid>
        <Grid
          item
          xs={7}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button variant="contained" onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Upload image</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <ProcessingPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onConfirm={() => handleDialogState({ add: false })}
          addProcessing={addProcessing}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      )}

      {dialogs.edit !== false && (
        <ProcessingPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          processState={dialogs.edit}
          updateProcessing={updateProcessing}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this image?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteUserAction}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          {imageIds.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="tableTitle">IMAGE</Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Typography variant="tableTitle">ACTIONS</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {imageIds.length ? (
              imageIds.map((imageId) => (
                <TableRow hover key={imageId} className={classes.cursor}>
                  <TableCell>
                    <Image id={imageId} mode={mode.images} />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="Edit"
                          onClick={() => handleDialogState({ edit: imageId })}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="Delete"
                          onClick={(e) => handleDialogState({ delete: imageId }, e)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Empty />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <BasicPagination
        count={users?.last_page}
        page={users?.current_page}
        onChange={(_, page) => fetchUsersAction(page)}
      /> */}
    </Card>
  );
};

export default ProcessingTime;
