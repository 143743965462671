import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useStyles } from './styles';
import CustomersPopup from './Dialog/CustomersPopup';
import axios from 'axios';
import Image from 'components/ImageComponent';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { mode } from 'utils/mode';
import { reviewSchema } from 'utils/formValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import profile from '../../../../../assets/images/profile_blanl.png';
import { Empty } from 'antd';
import Cookies from 'universal-cookie';

const CustomersSay = () => {
  const cookies = new Cookies();

  const classes = useStyles();
  const [imageIds, setImageIds] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    control,
    setValue,
    formState: { errors },
    reset,
    field,
  } = useForm({
    resolver: yupResolver(reviewSchema),
    defaultValues: {
      name: '',
      comment: '',
    },
  });

  const handleFileUpload = (fileData) => {
    setUploadedFile(fileData);
  };

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };

  const deleteReview = async (customerSayId) => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      await axios.delete(
        `${process.env.REACT_APP_API_KEY}coverbox/management/customersay/delete?customerSayId=${customerSayId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );
      localStorage.removeItem('customersay');
      const updatedImageIds = imageIds.filter((customerSay) => customerSay.id !== customerSayId);
      setImageIds(updatedImageIds);
      handleDialogState({ delete: false });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomerSayById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getAllCustomerSay`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      const customerSayData = response.data;
      localStorage.setItem('customersay', JSON.stringify(customerSayData));
      setImageIds(customerSayData);
    } catch (error) {
      console.log(error);
    }
  };

  const addReview = async (data) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('comment', data.comment);
    if (uploadedFile) {
      formData.append('image', uploadedFile);
    } else {
      formData.append('image', new Blob([profile]), 'profile.png');
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_KEY}coverbox/management/customersay/add`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,
        },
      }
    );
    if (response.status === 200) {
      handleFileUpload(response.data);
      // handleClose();
      setImageIds((prevGalleryItems) => [...prevGalleryItems, response.data]);

      localStorage.setItem('customersay', JSON.stringify(response.data));
    }
  };
  const updateReview = async (id, data) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('customerSayId', id);
    formData.append('comment', data.comment);
    if (uploadedFile) {
      formData.append('newImage', uploadedFile, uploadedFile.name);
    } else {
      formData.append('newImage', new File([], profile));
    }

    const response = await axios.put(
      `${process.env.REACT_APP_API_KEY}coverbox/management/customersay/update`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    if (response.status === 200) {
      // handleClose();

      localStorage.setItem('customersay', JSON.stringify(response.data));
      const customerSay = response.data;
      setValue('name', customerSay.name);
      setValue('comment', customerSay.comment);
      setValue('newImage', customerSay.id);

      const updatedCustomersay = imageIds.map((customerSay) =>
        customerSay.id === id ? fetchCustomerSayById() : customerSay.id
      );
      setImageIds(updatedCustomersay);
    }
  };

  useEffect(() => {
    fetchCustomerSayById();
  }, [uploadedFile]);

  return (
    <Card data-tid="staff-page">
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.header}>
        <Grid item xs={5} sm={7} md={4} lg={3}></Grid>
        <Grid
          item
          xs={7}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button variant="contained" onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Upload image</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <CustomersPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onConfirm={() => handleDialogState({ add: false })}
          addReview={addReview}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          reset={reset}
          setError={setError}
          errors={errors}
          control={control}
          // serverError={serverError}
          setValue={setValue}
          register={register}
          handleSubmit={handleSubmit}
        />
      )}
      {dialogs.edit && (
        <CustomersPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          customers={dialogs.edit}
          updateReview={updateReview}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          setValue={setValue}
          reset={reset}
          setError={setError}
          errors={errors}
          control={control}
          // serverError={serverError}
          register={register}
          handleSubmit={handleSubmit}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this image?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteReview}
          dataId={dialogs.delete}
        />
      )}
      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          {imageIds.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="tableTitle">FULL NAME</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle">REVIEW</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle">IMAGE</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="tableTitle">ACTIONS</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {imageIds.length ? (
              imageIds.map((customerSay) => {
                return (
                  <TableRow hover key={customerSay.id} className={classes.cursor}>
                    <TableCell>
                      <Typography variant="text">{customerSay?.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{customerSay?.comment}</Typography>
                    </TableCell>
                    <TableCell>
                      <Image id={customerSay.id} mode={mode.customerSayId} />
                    </TableCell>
                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="Edit"
                            onClick={(e) => handleDialogState({ edit: customerSay }, e)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => handleDialogState({ delete: customerSay.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Empty />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CustomersSay;
