import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import { HANDLE_USER, LOAD_USER, INIT_API, REFRESH_TOKEN } from './actionType';

const user = JSON.parse(localStorage.getItem('user'));
const initial = initialState.auth;

const reducer = handleActions(
  {
    [HANDLE_USER]: (state, { payload }) => {
      return {
        ...state,
        user: payload,
        isLoad: false,
      };
    },
    [LOAD_USER]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [INIT_API]: (state, { payload }) => {
      console.log(state, 'state');
      return {
        ...state,
        user: payload,
        lists: payload.lists,
        settings: payload.lists.settings,
        isLoad: true,
      };
    },
    [REFRESH_TOKEN]: (state, { payload }) => {
      return {
        ...state,
        user: { ...user, accessToken: payload },
      };
    },
  },

  initial
);

export default reducer;
