import { makeStyles } from '@mui/styles';
import loginBackground from '../../../assets/images/logo.svg';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    minHeight: '100vh',
  },
  leftContent: {
    width: '60%',
    height: '100vh',
    float: 'left',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    background: '#061D3A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundImage: `url(${loginBackground})`,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightContent: {
    margin: '0 auto',
  },
}));
