import React, { useState } from 'react';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import { Card, Divider, Typography } from '@mui/material';
import { CustomersSay, ProcessingTime, SpecialCars } from '..';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/BasicTabs/TabPanel';

const HomeComponent = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [dialogState, setDialogState] = useState(false);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const setTabValue = (idx) => {
    setCurrentTab(idx);
  };

  return (
    <>
      <Card className={classes.card}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="subtitle1">
            Home
          </Typography>
        </Box>
        <Divider />

        <Tabs
          value={currentTab}
          onChange={(e, idx) => setTabValue(idx)}
          aria-label="secondary tabs example"
          variant={'scrollable'}
          // variant="fullWidth"
          scrollButtons={'auto'}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            flexShrink: 0,
            overflowX: 'auto !important',
            overflowY: 'hidden !important',
          }}>
          <Tab variant="text" label="Processing Time" />
          <Tab variant="text" label="Customers Say" />
        </Tabs>
        <Divider />

        <Box>
          <TabPanel value={currentTab} index={0}>
            <ProcessingTime />
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <CustomersSay />
          </TabPanel>
        </Box>
      </Card>
    </>
  );
};

export default HomeComponent;
