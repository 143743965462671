import palette from './palette';

export default {
  h1: {
    fontSize: '36px',
    lineHeight: '54px',
    fontWeight: '700',
    fontFamily: 'Poppins',

    color: palette.custom.black,
  },
  h2: {
    fontSize: '28px',
    lineHeight: '41px',
    fontWeight: '600',
    fontFamily: 'Poppins',

    color: palette.custom.black,
  },
  h3: {
    fontSize: '20px',
    lineHeight: '27px',
    fontWeight: '500',
    fontFamily: 'Poppins',

    color: palette.custom.darkGray,
  },
  h4: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    color: palette.custom.black,
  },
  h5: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: '600',
    fontFamily: 'Poppins',

    color: palette.custom.white,
  },
  h6: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '550',
    fontFamily: 'Poppins',
    color: palette.custom.darkGray,
  },
  subtitle1: {
    fontSize: '20px',
    fontWeight: '600',
    color: palette.custom.black,
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Poppins',

    color: palette.custom.gray,
  },
  subtitle3: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',

    color: palette.custom.black,
  },
  subtitle4: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',

    color: palette.custom.white,
  },

  body1: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',

    color: palette.custom.black,
  },
  body2: {
    fontSize: '12px',
    color: palette.custom.white,
  },
  label1: {
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '16px',
    fontFamily: 'Poppins',

    color: palette.custom.gray,
  },
  button: {
    textTransform: 'none',
  },
  actionText: {
    color: palette.custom.darkGray,
    fontFamily: 'Poppins',

    fontSize: '16px',
  },

  tableTitle: {
    color: palette.custom.blue,
    fontSize: '15px',
    fontFamily: 'Poppins',
    fontWeight: '700',
  },
  text: {
    color: palette.custom.darkGray,
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  errors: {
    color: palette.custom.red,
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
};
