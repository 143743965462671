import React, { useState } from 'react';
import { Box, TextField, Button, Card, Typography, Grid } from '@mui/material';
import { useStyles } from '../styles';
import { Link, useNavigate } from 'react-router-dom';
import { authPaths } from '../../../constants/auth';
import logo from '../../../assets/images/logo.jpg';
import axios from 'axios';
import { resetPassSchema } from 'utils/formValidation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'universal-cookie';

const ResetPasswordForm = () => {
  const cookies = new Cookies();

  const classes = useStyles();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState('');

  const [state, setState] = useState({
    email: '',
    oldPassword: '',
    newPassword: '',
  });
  const [response, setResponse] = useState([]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPassSchema),
  });

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const onSubmit = async () => {
    try {
      const xsrfToken = cookies.get('XSRF-TOKEN');

      const headers = {
        'X-XSRF-TOKEN': xsrfToken,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}coverbox/management/admin/resetPassword?email=${state.email}&newPassword=${state.newPassword}&oldPassword=${state.oldPassword},`,
        { headers }
      );
      setResponse(response.data);

      if (response.status) {
        navigate('/login');
      }
    } catch (error) {
      setServerError('Invalid email or password.');
    }
  };

  return (
    <>
      <Box data-tid="reset-password-form" className={classes.wrapper}>
        <Card className={classes.form} autoComplete="off">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="p" className={classes.textAttribute}>
                  Reset Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {serverError && (
                  <Typography variant="inherit" className={classes.serverError}>
                    {serverError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="dense"
                  {...register('email')}
                  error={errors.email ? true : false}
                  onChange={(e) => setStateValue('email', e.target.value)}
                  value={state.email}
                  onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                />
                <Typography variant="inherit" className={classes.red}>
                  {errors.email?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="oldPassword"
                  name="oldPassword"
                  label="Old Password"
                  fullWidth
                  margin="dense"
                  {...register('oldPassword')}
                  error={errors.oldPassword ? true : false}
                  onChange={(e) => setStateValue('oldPassword', e.target.value)}
                  value={state.oldPassword}
                  onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                />
                <Typography variant="inherit" className={classes.red}>
                  {errors.oldPassword?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  fullWidth
                  margin="dense"
                  {...register('newPassword')}
                  error={errors.newPassword ? true : false}
                  onChange={(e) => setStateValue('newPassword', e.target.value)}
                  value={state.newPassword}
                  onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                />
                <Typography variant="inherit" className={classes.red}>
                  {errors.newPassword?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.textAttribute} size="small">
                  <Link className={classes.linkedText} to={authPaths.login}>
                    Go to login page
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit" className={classes.submitBtn}>
                  <Typography variant="h5">Submit</Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
    </>
  );
};

export default ResetPasswordForm;
