import React from 'react';
import { authPaths } from '../../constants/auth';
import { Login, ResetPassword } from '../../pages/auth';
import { UnauthorizedRoute } from '../components/UnauthorizedRoute';
import Layout from '../../components/Layout';
import { pagesPaths } from '../../constants/pagesPaths';
import Calendar from '../../pages/Calendar';
import Appointments from '../../pages/Appointments';
import { PrivateRoute } from '../components/PrivateRoute';
import Settings from 'pages/Settings';
import { Covering, Gallery, Home, Protection, TintedGlass } from 'pages';
import MyProfile from 'pages/auth/MyProfile';

export const AuthRouter = {
  routes: [
    {
      path: authPaths.login,
      element: <UnauthorizedRoute component={<Login />} />,
    },
    {
      path: authPaths.resetPassword,
      element: <UnauthorizedRoute component={<ResetPassword />} />,
    },
  ],
  paths: authPaths,
};

export const AdminRouter = {
  routes: [
    {
      path: '/',
      element: <PrivateRoute component={<Layout />} />,
      children: [
        {
          path: pagesPaths.calendar,
          element: <Calendar />,
        },
        {
          path: pagesPaths.tintedGlass,
          element: <TintedGlass />,
        },
        {
          path: pagesPaths.home,
          element: <Home />,
        },
        {
          path: pagesPaths.gallery,
          element: <Gallery />,
        },
        {
          path: pagesPaths.covering,
          element: <Covering />,
        },
        {
          path: pagesPaths.protection,
          element: <Protection />,
        },
        {
          path: pagesPaths.appointments,
          element: <Appointments />,
        },
        {
          path: pagesPaths.profile,
          element: <MyProfile />,
        },
        {
          path: pagesPaths.settings,
          element: <Settings />,
        },
        // {
        //   path: pagesPaths.contacts,
        //   element: <Contacts />,
        // },
      ],
    },
  ],
  paths: pagesPaths,
};

export const ManagerRouter = {};
export const UserRouter = {};
