import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { Empty } from 'antd';
import PartnersPopup from './Dialog/PartnersPopup';
import axios from 'axios';
import Image from 'components/ImageComponent';
import { mode } from 'utils/mode';
import Cookies from 'universal-cookie';

const Partners = () => {
  const cookies = new Cookies();

  const classes = useStyles();
  const [partnerIds, setPartnerIds] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const handleFileUpload = (fileData) => {
    setUploadedFile(fileData);
  };
  const [state, setState] = useState({
    imageType: 'COVERING',
    images: [],
  });

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };

  const deleteImage = async (partnerId) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_KEY}coverbox/management/partner/delete?partnerId=${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      localStorage.removeItem('coveringPartners');
      setPartnerIds((prevImageIds) => prevImageIds.filter((id) => id !== partnerId));
      handleDialogState({ delete: false });
    } catch (error) {
      console.log(error);
    }

    handleDialogState({ delete: false });
  };

  const fetchImageById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}coverbox/public/getPartnerImagesByImageType`,
        {
          params: {
            imageType: 'COVERING',
          },
        }
      );

      const partnerIds = response.data;
      localStorage.setItem('coveringPartners', JSON.stringify(partnerIds));
      setPartnerIds(partnerIds);
    } catch (error) {
      console.log(error);
    }
  };

  const addPartner = async () => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('imageType', 'COVERING');
    formData.append('images', uploadedFile);

    const response = await axios.post(
      `${process.env.REACT_APP_API_KEY}coverbox/management/partner/create`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    if (response.status === 200) {
      handleFileUpload(response.data);
      // handleClose();
      setPartnerIds((prevGalleryItems) => [...prevGalleryItems, response.data]);
      localStorage.setItem('coveringPartners', JSON.stringify(response.data));
    }
  };

  const updatePartner = async (id) => {
    const xsrfToken = cookies.get('XSRF-TOKEN');

    const formData = new FormData();
    formData.append('imageType', state.imageType);
    formData.append('partnerId', id);
    formData.append('newImage', uploadedFile);
    const response = await axios.put(
      `${process.env.REACT_APP_API_KEY}coverbox/management/partner/update`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-XSRF-TOKEN': xsrfToken,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    if (response.status === 200) {
      // handleClose();
      localStorage.setItem('coveringPartners', JSON.stringify(response.data));
      const updatedImageIds = partnerIds.map((partnerId) =>
        partnerId === id ? fetchImageById() : partnerId
      );
      setPartnerIds(updatedImageIds);
    }
  };
  useEffect(() => {
    fetchImageById();
  }, [uploadedFile]);

  return (
    <Card data-tid="staff-page">
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.header}>
        <Grid item xs={5} sm={7} md={4} lg={3}>
          {/* <BasicSearch value={searchQuery} onChange={setSearchQuery} /> */}
        </Grid>
        <Grid
          item
          xs={7}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button variant="contained" onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Upload image</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <PartnersPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onConfirm={() => handleDialogState({ add: false })}
          addPartner={addPartner}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      )}

      {dialogs.edit !== false && (
        <PartnersPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          partners={dialogs.edit}
          updatePartner={updatePartner}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this image?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteImage}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          {partnerIds.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="tableTitle">IMAGE</Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Typography variant="tableTitle"> ACTIONS</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {partnerIds.length ? (
              partnerIds?.map((partnerId) => {
                return (
                  <TableRow hover key={partnerId} className={classes.cursor}>
                    <TableCell>
                      <Image id={partnerId} mode={mode.partners} />
                    </TableCell>
                    <TableCell></TableCell>

                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="Edit"
                            onClick={(e) => handleDialogState({ edit: partnerId }, e)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => handleDialogState({ delete: partnerId }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Empty />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <BasicPagination
        count={users?.last_page}
        page={users?.current_page}
        onChange={(_, page) => fetchUsersAction(page)}
      /> */}
    </Card>
  );
};

export default Partners;
