import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import auth from './common/auth/reducer';
import events from './common/events/reducer';
import staff from './common/staff/reducer';
import settings from './common/settings/reducer';
import customers from './common/customers/reducer';
import appointments from './common/appointments/reducer';
import gallery from './common/gallery/reducer';
import protection from './common/ppf_protection/reducer';

import { initialState } from './initialState';

const RootReducer = combineReducers({
  auth,
  staff,
  appointments,
  customers,
  settings,
  events,
  gallery,
  protection,
});

export default createStore(
  RootReducer,
  initialState,
  compose(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
