import { Box } from '@mui/system';
import ResetPasswordForm from '../../../components/forms/ResetPasswordForm';
import { useStyles } from './styles';
import loginBackground from '../../../assets/images/logo.svg';

const ResetPassword = () => {
  const classes = useStyles();

  return (
    <Box data-tid="reset-password-page" className={classes.container}>
      <Box className={classes.leftContent}>
        <img src={loginBackground} alt="" style={{ width: '450px', height: '450px' }} />
      </Box>{' '}
      <Box className={classes.rightContent}>
        <ResetPasswordForm />
      </Box>
    </Box>
  );
};

export default ResetPassword;
