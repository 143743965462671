export const INIT_API = 'api/v1/token';
export const LOGIN_API = 'api/v1/auth/authenticate';
export const RESET_PASS_API = 'coverbox/management/admin/resetPassword';
export const REFRESH_TOKEN = 'api/v1/token';
export const APPOINTMENTS_API = 'coverbox/management/appointment/getAll';

export const ALL_SERVICES = 'coverbox/public/getAllServiceDTO';
export const GALLERY_API = 'coverbox/management/gallery/add';
export const STAFF_API = 'coverbox/management/gallery/delete';
export const PROFILE_API = 'api/users';
export const EVENTS_API = 'api/events';
export const LOGOUT_API = 'auth/logout';
export const CHANGES_API = 'api/changes';
export const CUSTOMERS_API = 'api/customers';
export const COOKIE_API = '';
export const EVENT_MENU_API = 'api/event-menus';
export const CALENDAR_API = 'api/calendar-items';
export const CREATE_PASS_API = 'coverbox/management/admin/resetPassword';
export const SETTINGS_UPDATE_API = 'coverbox/public/appointment/appoint';
