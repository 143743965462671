export default {
  primary: {
    main: '#F5F8FA',
    dark: '#061D3A',
  },
  secondary: {
    main: '#42A5F5',
  },

  custom: {
    black: '#000000',
    white: '#FFFFFF',
    gray: 'rgb(235, 235, 235)',
    lightGray: '#f5f8fa',
    darkGray: '#86899d',
    red: '#D43333',
    lightGreen: '#42CE10',
    green: '#3BB90E',
    orange: '#EB7229',
    blue: '#0095E5',
    lightBlue: '#42A5F5',
    hover: 'rgba(0,0,0,0.08)',
  },
};
