import { pagesPaths } from './pagesPaths';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import OpacitySharpIcon from '@mui/icons-material/OpacitySharp';
import VerifiedUserSharpIcon from '@mui/icons-material/VerifiedUserSharp';
import AutoAwesomeMotionSharpIcon from '@mui/icons-material/AutoAwesomeMotionSharp';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import CollectionsIcon from '@mui/icons-material/Collections';

export const sidebarList = [
  {
    name: 'calendar',
    link: pagesPaths.calendar,
    icon: <CalendarMonthIcon />,
    roles: ['admin', 'manager', 'user'],
  },
  {
    name: 'home',
    link: pagesPaths.home,
    icon: <HomeIcon />,
    roles: ['admin', 'manager', 'user'],
  },
  {
    name: 'covering',
    link: pagesPaths.covering,
    icon: <AutoAwesomeMotionSharpIcon />,
    roles: ['admin', 'manager', 'user'],
  },
  {
    name: 'appointments',
    link: pagesPaths.appointments,
    icon: <EventAvailableIcon />,
    roles: ['admin', 'manager', 'user'],
  },
  {
    name: 'gallery',
    link: pagesPaths.gallery,
    icon: <CollectionsIcon />,
    roles: ['admin', 'manager', 'user'],
  },
  {
    name: 'tinted glass',
    link: pagesPaths.tintedGlass,
    icon: <OpacitySharpIcon />,
    roles: ['admin', 'manager', 'user'],
  },
  {
    name: 'PPF protection',
    link: pagesPaths.protection,
    icon: <VerifiedUserSharpIcon />,
    roles: ['admin', 'manager'],
  },
];
