import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useState } from 'react';
import { Dialog, DialogContent, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { calendarColors } from 'constants/calendar';
import { useStyles } from './styles';
import './style.scss';

const localizer = momentLocalizer(moment);

const styles = (appointment) => {
  let backgroundColor = !appointment?.allDay ? '#e0e2e4' : '#029BF3';
  let color = !appointment?.allDay ? '#7E8299' : '#fff';

  if (appointment?.colorId && calendarColors[appointment.colorId]) {
    backgroundColor = calendarColors[appointment.colorId].background;
    color = calendarColors[appointment.colorId].foreground;
  }

  return {
    style: {
      backgroundColor: backgroundColor,
      color: color,
      borderRadius: '5px',
      display: 'block',
    },
  };
};

const BasicCalendar = ({ appointments, tintGlass }) => {
  const classes = useStyles();
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const events = appointments.map((appointment, index) => {
    const { firstName, lastName } = appointment.customer;
    const getCarModel = Object.values(tintGlass).find(
      (value) => value?.id === appointment.tintGlassId
    );
    const carModel = getCarModel ? getCarModel.model_en : 'Unknown Car Model';
    return {
      id: appointment?.id,
      start: moment(appointment?.appointmentTime).toDate(),
      end: moment(appointment?.endTime).toDate(),
      title: `${firstName} ${lastName}`,
      carModel: carModel,
      allDay: false,
    };
  });

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const handleCloseDialog = () => {
    setSelectedAppointment(null);
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={styles}
        style={{ height: '100%' }}
        onSelectEvent={handleAppointmentClick}
      />

      {selectedAppointment && (
        <Dialog className={classes.overflow} open={true} onClose={handleCloseDialog}>
          <DialogContent className={classes.content}>
            <Box className={classes.header}>
              <div className={classes.wrapper}>
                <CalendarMonthIcon />
                <Typography sx={{ marginLeft: '15px' }} variant="h6">
                  {selectedAppointment.title}
                </Typography>
                <Typography sx={{ marginLeft: '15px' }} variant="h6">
                  {selectedAppointment.carModel}
                </Typography>
              </div>
              <ClearIcon onClick={handleCloseDialog} />
            </Box>

            <Divider />

            <Box className={classes.body}>
              <Typography variant="text">
                <b>Starts:</b> {moment(selectedAppointment.start).format('YYYY-MM-DD HH:mm:00')}
              </Typography>
              <Typography variant="text">
                <b>Ends:</b> {moment(selectedAppointment.end).format('YYYY-MM-DD HH:mm:00')}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default BasicCalendar;
