export const pagesPaths = {
  base: '/calendar',
  calendar: '/calendar',
  events: '/events',
  event: '/events/:id',
  staff: '/staff',
  profile: '/profile',
  customers: '/customers',
  appointments: '/appointments',
  changes: '/changes',
  settings: '/settings',
  tintedGlass: '/tintedglass',
  protection: '/protection',
  home: '/home',
  covering: '/covering',
  gallery: '/gallery',
};
