import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: { padding: '25px' },

  button: {
    height: '40px',
    cursor: 'pointer',
  },

  content: {
    overflow: 'hidden !important',
    padding: '15px!important',
  },
  overflow: { overflowY: 'hidden!important' },
  textField: {
    background: '#f5f8fa',
    width: '350px !important',
    maxWidth: '400px !important',
    [theme.breakpoints.down('md')]: {
      width: '220px !important',
    },
  },

  cancel: {
    marginRight: '10px !important',
    background: '#f5f8fa !important',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '60px',
    alignItems: 'center',
  },
  header: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px',
    cursor: 'pointer',
  },

  errorField: {
    marginTop: '20px !important',

    '& label.Mui-focused': {
      color: 'red !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red !important',
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 'unset',
    },
  },
  error: {
    color: 'red !important',
  },
  label: {
    color: 'red!important',
  },
  outlinedInput: {
    '&$error input': {
      borderColor: 'red!important',
    },
    border: '1px solid black!important',
  },
  errorTextField: {
    marginTop: '20px !important',
    '& .MuiInputLabel-root': {
      color: 'black!important',
    },
    '& .MuiInputLabel-shrink': {
      color: 'black!important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid black!important',
      },
    },
    '&.Mui-error': {
      '& .MuiInputLabel-root': {
        color: 'red!important',
      },
      '& .MuiInputLabel-shrink': {
        color: 'red!important',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '1px solid red!important',
        },
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 'unset',
    },
  },
  textfield: {
    marginTop: '20px !important',

    '& label.Mui-focused': {
      color: 'inherit',
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'inherit',
        border: '1px solid black!important',
      },
      '&:hover fieldset': {
        borderColor: 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'inherit',
      },
      '&.Mui-error fieldset': {
        borderColor: 'red !important',
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 'unset',
    },
  },

  grey: {
    color: 'darkgrey!important',
  },
  // black: {
  //   backgroundColor: 'black !important',
  // },
}));
