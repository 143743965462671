import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
});
export const resetPassSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  oldPassword: Yup.string()
    .required('Old Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
  newPassword: Yup.string()
    .required('New Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
});

// export const appointmentSchema = Yup.object().shape({
//   firstName: Yup.string().required('First Name is required'),
//   lastName: Yup.string().required('Last Name is required'),
//   email: Yup.string().required('Email is required').email('Email is invalid'),
//   phoneNumber: Yup.string().required('Phone number is requires'),
//   appointmentTime: Yup.string().required('Date is required'),
// });

//validation schema
export const appointmentSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  tintGlassId: Yup.string().required('Car Model is required'),

  // appointmentTime: Yup.string().required('Date is required'),
  date: Yup.string().required('Date is required'),
  time: Yup.string().required('Time is required'),
  durationInMinutes: Yup.string().required('Duration is required'),
});

export const reviewSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  comment: Yup.string().required('Comment is required'),
});

export const detailsSchema = Yup.object().shape({
  description_en: Yup.string().required('Description is required'),
  description_fr: Yup.string().required('Description is required'),
  price: Yup.number().required('Price is required'),
  durationInMinutes: Yup.number().required('Duration is required'),
  discountPrice: Yup.number().required('Discount price  is required'),
});

export const carTintSchema = Yup.object().shape({
  model_en: Yup.string().required('Description is required'),
  model_fr: Yup.string().required('Description is required'),
  price: Yup.number().required('Price is required'),
  durationInMinutes: Yup.number().required('Duration is required'),
  discountPrice: Yup.number().required('Discount price  is required'),
});
