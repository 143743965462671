import React, { forwardRef, useEffect } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Grow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import BasicCheckbox from 'components/BasicCheckbox';
import FileUpload from 'components/ImageUpload';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const SpecialCarsPopup = ({
  handleClose,
  tintedState,
  onConfirm,
  mode,
  uploadedFile,
  setUploadedFile,
  register,
  setValue,
  errors,
  addCarTint,
  updateCarTint,
  handleSubmit,
  reset,
  watch,
  setError,
  model_en,
  model_fr,
  serverError,
  setServerError,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (tintedState) {
      setValue('model_en', tintedState?.model_en);
      setValue('model_fr', tintedState?.model_fr);
      setValue('price', tintedState?.price);
      setValue('discountPrice', tintedState?.discountPrice);
      setValue('durationInMinutes', 60);
      setValue('isSpecial', tintedState?.isSpecial);
    }
  }, [tintedState, setValue]);

  useEffect(() => {
    if (serverError && serverError.startsWith('TintGlassService with model name')) {
      errors.model_en &&
        setError('model_en', {
          type: 'custom',
          message: serverError,
        });
    }
  }, [serverError]);
  useEffect(() => {
    if (serverError && serverError.startsWith('TintGlassService with model name')) {
      errors.model_fr &&
        setError('model_fr', {
          type: 'custom',
          message: serverError,
        });
    }
  }, [serverError]);

  const sendForm = async (data) => {
    try {
      if (mode === 'add') {
        await addCarTint(data);
      } else {
        updateCarTint(tintedState.id, data);
      }

      if (serverError && serverError.response.status === 409) {
        setServerError('The resource already exists. Please choose a different one.');
        return;
      }

      if (onConfirm && !serverError) {
        onConfirm(tintedState.id);
        reset();
        handleClose();
      }
    } catch (error) {
      console.error('Error:', error);
      setServerError('An error occurred. Please try again later.');
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">{mode === 'add' ? 'Add new car' : 'Edit car'}</Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} autoComplete="off">
          <form onSubmit={handleSubmit(sendForm)} noValidate>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField
                    label="CarModel En"
                    fullWidth
                    type="text"
                    {...register('model_en')}
                    error={!!errors.model_en}
                    helperText={errors.model_en?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="CarModel Fr"
                    fullWidth
                    type="text"
                    {...register('model_fr')}
                    error={!!errors.model_fr}
                    helperText={errors.model_fr?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Price"
                    fullWidth
                    type="number"
                    {...register('price')}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Discount"
                    fullWidth
                    type="number"
                    {...register('discountPrice')}
                    error={!!errors.discountPrice}
                    helperText={errors.discountPrice?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BasicCheckbox
                    name="isSpecial"
                    label="Special"
                    fullWidth
                    type="text"
                    {...register('isSpecial')}
                    checked={watch('isSpecial') || false}
                    onChange={(value) => setValue('isSpecial', value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileUpload
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    specialCarId={tintedState?.id}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.buttonWrapper}>
              <Button
                size="small"
                variant="contained"
                className={classes.cancel}
                onClick={handleClose}>
                <Typography variant="subtitle3">Cancel</Typography>
              </Button>
              <Button size="small" variant="contained" color="primary" type="submit">
                <Typography variant="subtitle4">{mode === 'add' ? 'Create' : 'Edit'}</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SpecialCarsPopup;
