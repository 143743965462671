// AdditionalService Component
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grow,
  InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const CarWindowPopup = ({
  handleClose,
  onConfirm,
  mode,
  errors,
  additionalService,
  handleSubmit,
  register,
  addDetail,
  updateDetail,
  setValue,
}) => {
  const classes = useStyles();

  const sendForm = async (data) => {
    if (mode === 'add') {
      addDetail(data);
    } else {
      updateDetail(additionalService?.id, data);
    }

    if (onConfirm) {
      onConfirm(additionalService?.id);
    }
  };

  useEffect(() => {
    if (additionalService) {
      setValue('description_en', additionalService?.description_en);
      setValue('description_fr', additionalService?.description_fr);
      setValue('durationInMinutes', additionalService?.durationInMinutes);
      setValue('price', additionalService?.price);
      setValue('discountPrice', additionalService?.discountPrice);
    }
  }, [additionalService, setValue]);

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">{mode == 'add' ? 'Add new data' : 'Edit data'}</Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} autoComplete="off">
          <form onSubmit={handleSubmit(sendForm)} noValidate>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField
                    label="Description En"
                    fullWidth
                    type="text"
                    {...register('description_en')}
                    error={!!errors.description_en}
                    helperText={errors.description_en?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Description Fr"
                    fullWidth
                    type="text"
                    {...register('description_fr')}
                    error={!!errors.description_fr}
                    helperText={errors.description_fr?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Price"
                    fullWidth
                    type="number"
                    {...register('price')}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Discount"
                    fullWidth
                    type="number"
                    {...register('discountPrice')}
                    error={!!errors.discountPrice}
                    helperText={errors.discountPrice?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Duration"
                    fullWidth
                    type="number"
                    {...register('durationInMinutes')}
                    error={!!errors.durationInMinutes}
                    helperText={errors.durationInMinutes?.message}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.buttonWrapper}>
              <Button
                size="small"
                variant="contained"
                className={classes.cancel}
                onClick={handleClose}>
                <Typography variant="subtitle3">Cancel</Typography>
              </Button>
              <Button size="small" variant="contained" color="primary" type="submit">
                <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Edit'}</Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CarWindowPopup;
