import React, { useEffect, useState } from 'react';

import BasicCalendar from 'components/BasicCalendar';
import { Card } from '@mui/material';
import { useStyles } from './styles';
import axios from 'axios';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [tintGlass, setTintGlass] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_KEY}coverbox/management/appointment/getAll`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );
    if (response.status) {
      setAppointments(response.data);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_KEY}coverbox/public/getAllTintGlassServiceDTO`,
          {
            params: {
              page: 0,
              size: 500,
            },
          }
        );

        setTintGlass(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Card className={classes.wrapper}>
      <BasicCalendar tintGlass={tintGlass} events={events} appointments={appointments} />
    </Card>
  );
};

export default Calendar;
