// import React from 'react';
// import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// import WatchLaterIcon from '@mui/icons-material/WatchLater';
// import PropTypes from 'prop-types';
// import { Controller } from 'react-hook-form';
// /* eslint-disable  no-unused-vars, no-debugger */

// const ServiceTimePicker = ({ control, duration, timeWatcher }) => {
//   const getTimeArray = (duration) => {
//     const timeArray = [];
//     const timeSlot = duration;
//     const startTime = 8;
//     const endTime = 19;

//     for (let hour = startTime; hour < endTime; hour++) {
//       for (let minute = 0; minute < 60; minute += timeSlot) {
//         const totalMinutes = hour * 60 + minute;
//         const hourEnd = Math.floor((totalMinutes + duration) / 60);
//         const minuteEnd = (totalMinutes + duration) % 60;
//         const timeSlotStart = `${hour.toString().padStart(2, '0')}:${minute
//           .toString()
//           .padStart(2, '0')}`;
//         const timeSlotEnd = `${hourEnd.toString().padStart(2, '0')}:${minuteEnd
//           .toString()
//           .padStart(2, '0')}`;
//         const timeRange = `${timeSlotStart} - ${timeSlotEnd}`;
//         timeArray.push(timeRange);
//       }
//     }

//     return timeArray;
//   };

//   const timeArray = getTimeArray(duration);

//   return (
//     <FormControl fullWidth>
//       <InputLabel id="service-time-label">Time</InputLabel>
//       <Controller
//         name="time"
//         control={control}
//         render={({ field }) => (
//           <Select
//             {...field}
//             labelId="service-time-label"
//             label="Time"
//             IconComponent={WatchLaterIcon}
//             MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
//             value={field.value}
//             onChange={(e) => field.onChange(e.target.value)}>
//             {timeArray.map((option) => (
//               <MenuItem
//                 key={option}
//                 value={option}
//                 style={{
//                   background: option === field.value ? '#1A1C3A' : '',
//                   color: option === field.value ? '#FCFCFC' : '#1A1C3A',
//                 }}>
//                 {option}
//               </MenuItem>
//             ))}
//           </Select>
//         )}
//       />
//     </FormControl>
//   );
// };

// ServiceTimePicker.propTypes = {
//   control: PropTypes.object.isRequired,
//   duration: PropTypes.number.isRequired,
//   timeWatcher: PropTypes.any,
// };

// export default ServiceTimePicker;

// import React from 'react';
// import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
// import WatchLaterIcon from '@mui/icons-material/WatchLater';
// import PropTypes from 'prop-types';
// import { Controller } from 'react-hook-form';

// const ServiceTimePicker = ({ control, duration, validate, errors }) => {
//   const getTimeArray = (duration) => {
//     const timeArray = [];
//     const startTime = 8;
//     const lunchStart = 12;
//     const lunchEnd = 13;
//     const endTime = 18;

//     let currentHour = startTime;
//     let currentMinute = 0;

//     while (currentHour < endTime || (currentHour === endTime && currentMinute < 60)) {
//       const totalMinutes = currentHour * 60 + currentMinute;

//       if (currentHour < lunchStart) {
//         // Calculate time slot before lunch break
//         let hourEnd = Math.floor((totalMinutes + duration) / 60);
//         let minuteEnd = (totalMinutes + duration) % 60;

//         // Adjust for lunch break
//         if (
//           (hourEnd === lunchStart && minuteEnd === 0) ||
//           (hourEnd === lunchEnd && minuteEnd === 0)
//         ) {
//           hourEnd = lunchEnd + 1;
//           minuteEnd = 0;
//         } else if (
//           (hourEnd > lunchStart && totalMinutes < lunchStart * 60) ||
//           (hourEnd === lunchStart && minuteEnd < 60)
//         ) {
//           hourEnd += 1;
//           minuteEnd = (minuteEnd + 60 - currentMinute) % 60;
//         }

//         const timeSlotStart = `
//         ${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//         const timeSlotEnd = `
//         ${hourEnd.toString().padStart(2, '0')}:${minuteEnd.toString().padStart(2, '0')}`;
//         const timeRange = `${timeSlotStart} - ${timeSlotEnd}`;

//         timeArray.push(timeRange);

//         // Update currentHour and currentMinute for the next time slot
//         currentHour = hourEnd;
//         currentMinute = minuteEnd;
//       } else if (
//         currentHour === lunchStart &&
//         currentMinute === 0 &&
//         duration <= (lunchEnd - lunchStart) * 60
//       ) {
//         // Handle lunch break with a short duration appointment
//         const timeSlotStart = `
//         ${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//         const timeSlotEnd = `
//         ${lunchStart.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//         const timeRange = `${timeSlotStart} - ${timeSlotEnd}`;
//         timeArray.push(timeRange);

//         // Update currentHour and currentMinute for the next time slot
//         currentHour = lunchEnd;
//         currentMinute = 0;
//       } else {
//         // Calculate time slot after lunch break
//         let hourEnd = Math.floor((totalMinutes + duration) / 60);
//         let minuteEnd = (totalMinutes + duration) % 60;

//         if (currentHour < lunchEnd) {
//           hourEnd = Math.max(hourEnd, lunchEnd + 1);
//           minuteEnd = Math.max(minuteEnd, 0);
//         }

//         const timeSlotStart = `
//         ${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//         const timeSlotEnd = `
//         ${hourEnd.toString().padStart(2, '0')}:${minuteEnd.toString().padStart(2, '0')}`;
//         const timeRange = `${timeSlotStart} - ${timeSlotEnd}`;

//         timeArray.push(timeRange);

//         // Update currentHour and currentMinute for the next time slot
//         currentHour = hourEnd;
//         currentMinute = minuteEnd;
//       }
//     }

//     return timeArray;
//   };

//   const getTimeArrayHour = (duration) => {
//     const timeArray = [];
//     const startTime = 8;
//     const lunchStart = 12;
//     const lunchEnd = 13;
//     const endTime = 18;

//     let currentHour = startTime;
//     let currentMinute = 0;

//     while (currentHour < endTime || (currentHour === endTime && currentMinute < 60)) {
//       if (currentHour === lunchStart && currentMinute === 0) {
//         // Skip lunch break
//         currentHour = lunchEnd;
//         currentMinute = 0;
//       }

//       const totalMinutes = currentHour * 60 + currentMinute;
//       let hourEnd = Math.floor((totalMinutes + duration) / 60);
//       let minuteEnd = (totalMinutes + duration) % 60;

//       if (hourEnd > lunchStart && hourEnd < lunchEnd) {
//         // The appointment ends during lunch break, skip it
//         currentHour = lunchEnd;
//         currentMinute = 0;
//       } else {
//         const timeSlotStart = `
//         ${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//         const timeSlotEnd = `
//         ${hourEnd.toString().padStart(2, '0')}:${minuteEnd.toString().padStart(2, '0')}`;
//         const timeRange = `${timeSlotStart} - ${timeSlotEnd}`;

//         timeArray.push(timeRange);

//         // Update currentHour and currentMinute for the next time slot
//         if (minuteEnd === 0) {
//           currentHour = hourEnd;
//           currentMinute = 0;
//         } else {
//           currentHour = hourEnd;
//           currentMinute = minuteEnd;
//         }
//       }
//     }

//     return timeArray;
//   };

//   const timeArray =
//     duration == 60 || duration == 120 ? getTimeArrayHour(duration) : getTimeArray(duration);

//   return (
//     <FormControl fullWidth>
//       <InputLabel id="service-time-label">Time</InputLabel>
//       <Controller
//         name="time"
//         control={control}
//         render={({ field }) => (
//           <>
//             <Select
//               {...field}
//               labelId="service-time-label"
//               label="Time"
//               IconComponent={WatchLaterIcon}
//               MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
//               value={field?.value}
//               onChange={(e) => field.onChange(e.target.value)}>
//               {timeArray.map((option) => (
//                 <MenuItem
//                   key={option}
//                   value={option}
//                   style={{
//                     background: option === field.value ? '#1A1C3A' : '',
//                     color: option === field.value ? '#FCFCFC' : '#1A1C3A',
//                   }}>
//                   {option}
//                 </MenuItem>
//               ))}
//             </Select>
//           </>
//         )}
//       />
//       {errors.time && <FormHelperText>{errors.time.message}</FormHelperText>}
//       <FormHelperText>{validate}</FormHelperText>
//     </FormControl>
//   );
// };

// ServiceTimePicker.propTypes = {
//   control: PropTypes.object.isRequired,
//   duration: PropTypes.number.isRequired,
//   validate: PropTypes.any,
//   errors: PropTypes.any,
// };

// export default ServiceTimePicker;

// import React, { useMemo } from 'react';
// import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
// import WatchLaterIcon from '@mui/icons-material/WatchLater';
// import PropTypes from 'prop-types';
// import { Controller } from 'react-hook-form';
// import moment from 'moment';
// /* eslint-disable  no-unused-vars, no-debugger */
// const constructTimeRanges = (startTime, endTime) => {
//   return `${startTime} - ${endTime}`;
// };

// const ServiceTimePicker = ({ control, time, duration, validate, errors, mode }) => {
//   const usedTimeRanges = time.map((d) => {
//     const startTime = moment(d.appointmentTime).format('HH:mm');
//     const endTime = moment(d.appointmentTime).add(d.durationInMinutes, 'minutes').format('HH:mm');
//     return [startTime, endTime];
//   });

//   const getTimeMin = (duration) => {
//     const timeArray = [];
//     const startTime = 8;
//     const lunchStart = 12;
//     const lunchEnd = 13;
//     const endTime = 18;

//     let currentHour = startTime;
//     let currentMinute = 0;

//     while (currentHour < endTime || (currentHour === endTime && currentMinute < 60)) {
//       const totalMinutes = currentHour * 60 + currentMinute;

//       let hourEnd = Math.floor((totalMinutes + duration) / 60);
//       let minuteEnd = (totalMinutes + duration) % 60;

//       if (hourEnd === lunchStart && minuteEnd === 0) {
//         hourEnd = lunchEnd + 1;
//         minuteEnd = 0;
//       }

//       if (hourEnd > lunchStart && totalMinutes < lunchStart * 60) {
//         hourEnd += lunchEnd - lunchStart;
//         minuteEnd = 0;
//       }

//       if (hourEnd >= endTime) {
//         break;
//       }

//       const timeSlotStart = `
//       ${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//       const timeSlotEnd = `
//       ${hourEnd.toString().padStart(2, '0')}:${minuteEnd.toString().padStart(2, '0')}`;

//       const slotStartTime = moment(timeSlotStart, 'HH:mm');
//       const slotEndTime = moment(timeSlotEnd, 'HH:mm');

//       // timeArray.push([timeSlotStart, timeSlotEnd]);

//       const overlap = usedTimeRanges.some((usedRange) => {
//         const [usedStart, usedEnd] = usedRange;
//         const usedStartTime = moment(usedStart, 'HH:mm');
//         const usedEndTime = moment(usedEnd, 'HH:mm');

//         return slotStartTime.isBefore(usedEndTime) && slotEndTime.isAfter(usedStartTime);
//       });

//       if ((!overlap && mode == 'edit') || !overlap || mode == 'edit') {
//         timeArray.push([timeSlotStart, timeSlotEnd]);
//       }

//       if (hourEnd === lunchStart) {
//         currentHour = lunchEnd + 1;
//         currentMinute = 0;
//       } else {
//         currentHour = hourEnd;
//         currentMinute = minuteEnd;
//       }
//     }

//     return timeArray.map((t) => constructTimeRanges(t[0], t[1]));
//   };

//   const getTimeHour = (duration) => {
//     const timeArray = [];
//     const startTime = 8;
//     const lunchStart = 12;
//     const lunchEnd = 13;
//     const endTime = 18;

//     let currentHour = startTime;
//     let currentMinute = 0;

//     while (currentHour < endTime || (currentHour === endTime && currentMinute < 60)) {
//       if (currentHour === lunchStart && currentMinute === 0) {
//         currentHour = lunchEnd;
//         currentMinute = 0;
//       }

//       const totalMinutes = currentHour * 60 + currentMinute;
//       let hourEnd = Math.floor((totalMinutes + duration) / 60);
//       let minuteEnd = (totalMinutes + duration) % 60;

//       if (hourEnd > lunchStart && hourEnd < lunchEnd) {
//         currentHour = lunchEnd;
//         currentMinute = 0;
//       } else {
//         const timeSlotStart = `
//         ${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
//         const timeSlotEnd = `
//         ${hourEnd.toString().padStart(2, '0')}:${minuteEnd.toString().padStart(2, '0')}`;

//         // timeArray.push([timeSlotStart, timeSlotEnd]);

//         const slotStartTime = moment(timeSlotStart, 'HH:mm');
//         const slotEndTime = moment(timeSlotEnd, 'HH:mm');

//         const overlap = usedTimeRanges.some((usedRange) => {
//           const [usedStart, usedEnd] = usedRange;
//           const usedStartTime = moment(usedStart, 'HH:mm');
//           const usedEndTime = moment(usedEnd, 'HH:mm');

//           return slotStartTime.isBefore(usedEndTime) && slotEndTime.isAfter(usedStartTime);
//         });

//         if ((!overlap && mode == 'edit') || !overlap || mode == 'edit') {
//           timeArray.push([timeSlotStart, timeSlotEnd]);
//         }

//         if (minuteEnd === 0) {
//           currentHour = hourEnd;
//           currentMinute = 0;
//         } else {
//           currentHour = hourEnd;
//           currentMinute = minuteEnd;
//         }
//       }
//     }

//     return timeArray.map((t) => constructTimeRanges(t[0], t[1]));
//   };

//   const filteredTimeArray = useMemo(() => {
//     const availableTimeSlots =
//       duration == 60 || duration == 120 ? getTimeHour(duration) : getTimeMin(duration);
//     return availableTimeSlots;
//   }, [duration, usedTimeRanges]);

//   return (
//     <FormControl fullWidth>
//       <InputLabel id="service-time-label">Time</InputLabel>
//       <Controller
//         name="time"
//         control={control}
//         render={({ field }) => (
//           <>
//             <Select
//               {...field}
//               labelId="service-time-label"
//               label="Time"
//               IconComponent={WatchLaterIcon}
//               MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
//               value={field?.value}
//               onChange={(e) => field.onChange(e.target.value)}>
//               {filteredTimeArray.map((option) => (
//                 <MenuItem
//                   key={option}
//                   value={option}
//                   style={{
//                     background: option === field.value ? '#1A1C3A' : '',
//                     color: option === field.value ? '#FCFCFC' : '#1A1C3A',
//                   }}>
//                   {option}
//                 </MenuItem>
//               ))}
//             </Select>
//           </>
//         )}
//       />
//       {/* {errors.time && <FormHelperText>{errors.time.message}</FormHelperText>} */}
//       <FormHelperText>{validate}</FormHelperText>
//     </FormControl>
//   );
// };

// ServiceTimePicker.propTypes = {
//   control: PropTypes.object.isRequired,
//   time: PropTypes.array,
//   duration: PropTypes.number,
//   registeredAppointWithService: PropTypes.array,
// };

// export default ServiceTimePicker;

// import React from 'react';
// import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
// import WatchLaterIcon from '@mui/icons-material/WatchLater';
// import PropTypes from 'prop-types';
// import { Controller } from 'react-hook-form';
// import moment from 'moment';
// /* eslint-disable  no-unused-vars, no-debugger */
// const constructTimeRanges = (startTime, endTime) => {
//   return `${startTime} - ${endTime}`;
// };

// const ServiceTimePicker = ({ control, time, duration, validate, errors, mode }) => {
//   const usedTimeRanges = time.map((d) => {
//     const startTime = moment(d.appointmentTime).format('HH:mm');
//     const endTime = moment(d.appointmentTime).add(d.durationInMinutes, 'minutes').format('HH:mm');
//     return [startTime, endTime];
//   });

//   function getAvailableTimeSlots(durationInMinutes, usedTimeRanges) {
//     const availableTimeRanges = [];
//     const startTime = 8;
//     const lunchStart = 12;
//     const lunchEnd = 13;
//     const endTime = 18;

//     const workingHours = [
//       { start: startTime * 60, end: lunchStart * 60 },
//       { start: lunchEnd * 60, end: endTime * 60 },
//     ];

//     for (const hours of workingHours) {
//       for (
//         let startTime = hours.start;
//         startTime + durationInMinutes <= hours.end;
//         startTime += durationInMinutes
//       ) {
//         const endTime = startTime + durationInMinutes;
//         const startTimeString = convertToTimeString(startTime);
//         const endTimeString = convertToTimeString(endTime);

//         const slotStartTime = moment(startTimeString, 'HH:mm');
//         const slotEndTime = moment(endTimeString, 'HH:mm');

//         const overlap = usedTimeRanges.some((usedRange) => {
//           const [usedStart, usedEnd] = usedRange;
//           const usedStartTime = moment(usedStart, 'HH:mm');
//           const usedEndTime = moment(usedEnd, 'HH:mm');

//           return slotStartTime.isBefore(usedEndTime) && slotEndTime.isAfter(usedStartTime);
//         });

//         if (!overlap) {
//           availableTimeRanges.push(`${startTimeString} - ${endTimeString}`);
//         }
//       }
//     }

//     return availableTimeRanges;
//   }

//   function convertToTimeString(minutes) {
//     const hours = Math.floor(minutes / 60);
//     const mins = minutes % 60;
//     const hourString = hours.toString().padStart(2, '0');
//     const minString = mins.toString().padStart(2, '0');
//     return `${hourString}:${minString}`;
//   }

//   const availableSlots = getAvailableTimeSlots(duration, usedTimeRanges);

//   return (
//     <FormControl fullWidth>
//       <InputLabel id="service-time-label">Time</InputLabel>
//       <Controller
//         name="time"
//         control={control}
//         render={({ field }) => (
//           <>
//             <Select
//               {...field}
//               labelId="service-time-label"
//               label="Time"
//               IconComponent={WatchLaterIcon}
//               MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
//               value={field?.value}
//               onChange={(e) => field.onChange(e.target.value)}>
//               {availableSlots.map((option) => (
//                 <MenuItem
//                   key={option}
//                   value={option}
//                   style={{
//                     background: option === field.value ? '#1A1C3A' : '',
//                     color: option === field.value ? '#FCFCFC' : '#1A1C3A',
//                   }}>
//                   {option}
//                 </MenuItem>
//               ))}
//             </Select>
//           </>
//         )}
//       />
//       {/* {errors.time && <FormHelperText>{errors.time.message}</FormHelperText>} */}
//       <FormHelperText>{validate}</FormHelperText>
//     </FormControl>
//   );
// };

// ServiceTimePicker.propTypes = {
//   control: PropTypes.object.isRequired,
//   time: PropTypes.array,
//   duration: PropTypes.number,
//   registeredAppointWithService: PropTypes.array,
//   selectedDate: PropTypes.date,
//   validate: PropTypes.any,
//   errors: PropTypes.any,
//   mode: PropTypes.any,
// };

// export default ServiceTimePicker;
import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import moment from 'moment';
/* eslint-disable  no-unused-vars, no-debugger */
const constructTimeRanges = (startTime, endTime) => {
  return `${startTime} - ${endTime}`;
};

const ServiceTimePicker = ({
  control,
  time,
  duration,
  validate,
  errors,
  mode,
  timeRange,
  selectedDate,
}) => {
  let availableSlots = [];

  if (time && time.length > 0) {
    availableSlots = time.map((d) => {
      const startTime = moment(d.appointmentTime).format('HH:mm');
      const endTime = moment(d.appointmentTime).add(d.durationInMinutes, 'minutes').format('HH:mm');
      return constructTimeRanges(startTime, endTime);
    });
  } else {
    availableSlots;
  }
  // const now = new Date();

  // if (selectedDate.isSame(now, 'day') && now.getHours() > 18 && mode == 'add') {
  //   return availableSlots;
  // }

  return (
    <FormControl fullWidth>
      <InputLabel id="service-time-label">Time</InputLabel>
      <Controller
        name="time"
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...field}
              labelId="service-time-label"
              label="Time"
              IconComponent={WatchLaterIcon}
              MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
              value={field?.value}
              onChange={(e) => field.onChange(e.target.value)}>
              {mode == 'edit' ? (
                <MenuItem value={timeRange}>{timeRange}</MenuItem>
              ) : (
                availableSlots.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={{
                      background: option === field.value ? '#1A1C3A' : '',
                      color: option === field.value ? '#FCFCFC' : '#1A1C3A',
                    }}>
                    {option}
                  </MenuItem>
                ))
              )}
            </Select>
          </>
        )}
      />
      {errors.time && <FormHelperText>{errors.time.message}</FormHelperText>}
      <FormHelperText>{validate}</FormHelperText>
    </FormControl>
  );
};

ServiceTimePicker.propTypes = {
  control: PropTypes.object.isRequired,
  time: PropTypes.array,
  duration: PropTypes.number,
  registeredAppointWithService: PropTypes.array,
  selectedDate: PropTypes.date,
  validate: PropTypes.any,
  errors: PropTypes.any,
  mode: PropTypes.any,
  timeRange: PropTypes,
};

export default ServiceTimePicker;
