import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Typography, Button, Dialog, DialogContent, Divider, Box, Grow } from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';
import { STAFF_API } from 'api/api_routes';
import api from 'api';
import { handleUser } from 'store/common/auth/action';
import { updateStaff } from 'store/common/staff/action';
import axios from 'axios';
import FileUpload from 'components/ImageUpload';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const GalleryPopup = ({
  handleClose,
  userState,
  onConfirm,
  mode,
  addGallery,
  editGallery,
  uploadedFile,
  setUploadedFile,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState(null);
  const { lists } = useSelector(selectUser);
  const { user } = useSelector(selectUser);
  // const [selectedFile, setSelectedFile] = useState(null);

  const sendForm = () => {
    if (mode == 'add') {
      addGallery();
    } else {
      editGallery(userState);
    }
    if (onConfirm) {
      onConfirm(userState);
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode == 'add' ? 'Add image to Gallery' : 'Edit image to Gallery'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <FileUpload
                imageId={userState}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
              />
            </Grid>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button
              // disabled={isSaveDisabled}
              size="small"
              variant="contained"
              color="primary"
              onClick={sendForm}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Edit'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GalleryPopup;
