import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { DrawerHeader, useStyles } from './styles';
import logo from '../../assets/images/logo.svg';
import { SidebarConfig } from 'config/sidebarConfig';
import LinkedItem from './LinkedItem';
import { Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'utils/history';

const drawerWidth = 240;

function Sidebar({ children, window }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  // const { user } = useSelector(selectUser);
  const user = localStorage.getItem('userId');

  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    history.push('/login');
  };

  const role = 'admin';
  const [dropdown, setDropdown] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDropdown(typeof value === 'string' ? value.split(',') : value);
  };

  const drawer = (
    <>
      <DrawerHeader className={classes.header}>
        <img className={classes.logo} alt="Logo" src={logo} />
      </DrawerHeader>

      <Divider />

      <List>
        {SidebarConfig[role]?.map((item) => {
          return (
            <LinkedItem
              handleDrawerToggle={() => setMobileOpen(false)}
              {...{ ...item, open }}
              key={item.name}
            />
          );
        })}
      </List>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}>
        <Toolbar sx={{ background: 'white', paddingRight: '0 !important' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}>
            <MenuIcon />
          </IconButton>

          <Typography sx={{ marginLeft: 'auto' }} variant="h3" noWrap component="div">
            {`${user?.first_name || ''} ${user?.last_name || ''}`}
          </Typography>

          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={classes.userMenu}>
            <ArrowDropDownCircleIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={onLogout}>
              <Typography variant="text">Log Out</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}>
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>

      <Box sx={{ width: '100%' }}>{children}</Box>
    </Box>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
